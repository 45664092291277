/* FileUpload.css */

.upload-files-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 80px;
}

.folder_names {
  width: 100%;
}

.folder_names p {
  margin-bottom: 10px;
}

.folder-item {
  display: flex;
  border: 1px solid #7d7d7d;
  padding: 8px;
  /* background: white; */
  gap: 10px;
  border-radius: 5px;
  cursor: pointer;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  border-radius: 10px;
  font-weight: 500;
  box-sizing: border-box;
  white-space: nowrap;
  position: relative;
  text-overflow: ellipsis;
}

.upload-para p {
  font-size: 22px;
  font-weight: 600;
  color: #5d54f1;
  border: 3px solid black;
  padding: 15px;
  border-radius: 10px;
}
.upload-para {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.upload-files-container h2 {
  font-size: 40px;
  font-weight: 800;
  font-family: "Raleway";
}
.Upload_checkBoxes {
  margin-top: 20px;
  flex-direction: column;
  font-size: 20px;
  display: flex;
  border-radius: 20px;
  /* background: #e7e5fd; */
  padding: 40px;
  position: relative;
  width: 100%;
}

.folder_names {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
  overflow-y: hidden;
  width: 35%;
  margin: 10px;
  overflow: hidden;
}
.insider-name {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  height: 37vh;
  overflow: auto;
  gap: 20px;
  overflow-y: hidden;
  width: 80%;
  margin: 10px 10px 10px 0px;
  border-radius: 10px;
  overflow: auto;
}
.insider-name::-webkit-scrollbar {
  display: none;
}

.insider-name {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.folder_names p {
  margin-bottom: 0px;
  font-weight: 700;
  font-size: 22px;
  font-family: "Raleway";
}
.folder-item.selected {
  color: black;
  border: none;
  border-radius: 10px;
  padding: 7px 10px;
  border: 1px solid #7d7d7d;
  background: #f3f3f3;
}
.main-progress-bar-container {
  background: #5d54f1;
  width: 60%;
  margin: 30px;
  color: white;
  border-radius: 10px;
}

.upload-form {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}

.upload-files-container input[type="checkbox"] {
  height: 25px;
}

.upload-files-container input[type="file"] {
  font-size: 20px;
  font-family: raleway;
}

.upload-files-container input[type="text"] {
  height: 40px;
  padding: 20px;
  border-radius: 8px;
  font-size: 16px;
  border: 1px solid #7d7d7d;
  width: 100%;
  font-family: "Raleway";
  font-weight: 500;
}
.Judgement_upload_button {
  color: #fff;
  border: none;
  background-color: #000;
  border-radius: 5px;
  cursor: pointer;
  padding: 5px 17px;
  transition: background-color 0.3s;
  font-size: 20px;
  font-family: raleway;
}
.Judgement_chatRoom_Button {
  color: #fff;
  border: none;
  /* background-color: #5d54f1; */
  border-radius: 12px;
  cursor: pointer;
  padding: 5px 17px;
  transition: background-color 0.3s;
  font-size: 15px;
  box-shadow: 0 9px 14px rgba(0, 0, 0, 0.2), 0 13px 30px rgba(0, 0, 0, 0.2);
  font-family: raleway;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.createKnowledgeBase {
  margin: 0 auto;
}
/* 
.createKnowledgeBase label {
  font-family: raleway;
  text-align: center;
  font-size: 20px;
} */

.upload-file-contain {
  width: 100%;
  /* height: 100vh; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.upload-file-contain form {
  display: flex;
  flex-direction: row;
  gap: 15px;
  align-items: center;
  width: 100%;
}

.upload-judgement-container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 100px 0px 100px;
  width: 100%;
  gap: 50px;
}
.judgementUploadLeftSide {
  width: 100%;
  /* background: #e7e5fd; */
  padding: 24px 24px 2px 24px;
  border-radius: 15px;
  display: grid;
  position: relative;
}
.uploadJudgementRight {
  width: 50%;
  display: flex;
  flex-direction: column;
  gap: 30px;
}
.fileList-header {
  width: 100%;
  display: flex;
  padding: 10px 10px 0px 10px;
  align-items: center;
  justify-content: space-between;
}

.custom-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  opacity: 0;
  animation: fadeIn 0.3s forwards;
}

.custom-modal-header h2 {
  font-family: raleway;
  font-weight: 600;
}
.custom-modal-content {
  background: #fff;
  border-radius: 20px;
  padding: 20px;
  position: relative;
  max-width: 600px;
  width: 100%;
  z-index: 1001;
  transform: scale(0.9);
  animation: scaleUp 0.3s forwards;
}

.folder-headers {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 20px;
  font-family: "Raleway";
}

.chooseFile {
  margin: 25px;
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.upload-btn-wrapper {
  position: relative;
  overflow: hidden;
  display: inline-block;
  box-shadow: 0 9px 14px rgba(0, 0, 0, 0.2), 0 13px 30px rgba(0, 0, 0, 0.2);
}

.filebtn {
  border: 2px solid #000;
  color: #000;
  font-family: raleway;
  background-color: white;
  padding: 2px 8px;
  border-radius: 8px;
  font-size: 20px;
  font-weight: bold;
}

.upload-btn-wrapper input[type="file"] {
  font-size: 100px;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
}

.folder-headers h1 {
  font-size: 25px;
  margin-bottom: 0;
}
.folder-data {
  display: flex;
  align-items: center;
  /* border: 1px solid #5d54f1; */
  padding: 10px;
  gap: 10px;
  border-radius: 10px;
  justify-content: space-between;
}
.folders_data {
  padding: 20px;
  height: 30vh;
  width: 100%;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.custom-modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.custom-modal-body {
  margin-top: 20px;
}

.custom-close-button {
  background: transparent;
  border: none;
  font-size: 1.5em;
  cursor: pointer;
}

@keyframes fadeIn {
  to {
    opacity: 1;
  }
}

@keyframes scaleUp {
  to {
    transform: scale(1);
  }
}

.custom-close-button {
  background: transparent;
  border: none;
  font-size: 1.5em;
  cursor: pointer;
  color: #aaa;
  position: absolute;
  top: 10px;
  right: 10px;
}

/* .progress-bar {
    width: 100%;
    background-color: #e0e0e0;
    border-radius: 5px;
    overflow: hidden;
    margin-top: 10px;
    padding: 10px;
}

.progress-bar-fill {
    height: 40px;
    background-color: #76c7c0;
    text-align: center;
    line-height: 20px;
    color: white;
    transition: width 0.4s ease-in-out;
} */

.createRoom {
  /* background: #e7e5fd; */
  padding: 30px;
  align-items: center;
  display: flex;
  justify-content: center;
  border-radius: 20px;
  position: relative;
  width: 100%;
}

.right-right-section {
  display: flex;
  padding: 15px 15px 15px 0px;
}
.fileList {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
  overflow-y: hidden;
  width: 65%;
  margin: 10px;
  border: 1px solid #7d7d7d;
  overflow: auto;
  border-radius: 10px;
  background: white;
  position: relative;
}

.fileList-header h6 {
  font-family: Raleway;
  font-size: 20px;
  margin: 0;
  font-weight: 600;
}
.fileList-header button {
  background: #000;
  padding: 3px 10px 3px 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Raleway";
  font-weight: 500;
  box-shadow: 0 9px 14px rgba(0, 0, 0, 0.2), 0 13px 30px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  gap: 10px;
  font-size: 15px;
  color: white;
}
.styled-hr {
  /* border: none; */
  /* border-top: 1px solid #000; Change this to your desired color and thickness */
}

.upload_judgement_horizantal_line {
  width: 100%;
}
.judgementUploadLeftSide-top {
  width: 70%;
}
.head-container p {
  font-size: 20px;
  font-weight: 600;
  margin-left: 50px;
}
.circle {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #000;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  position: absolute;
  left: -26px;
  top: -5%;
  font-weight: 700;
  font-size: 30px;
}
.Upload_checkBoxes-head {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.Upload_checkBoxes-head p {
  margin-bottom: 0;
  font-size: 20px;
  font-weight: 600;
  text-align: center;
}
.createRoom-head {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.createRoom-head p {
  font-size: 20px;
  font-weight: 600;
  text-align: center;
}

.firstStep {
  display: flex;
  gap: 10px;
  width: 50%;
}
.upload-options-toggle-button {
  margin-left: 10px;
  background: none;
  border: none;
  font-size: 18px;
  margin-top: 0px;
  color: black;
  cursor: pointer;
  align-items: center;
  display: flex;
}
.judgement-chat-options {
  display: flex;
  gap: 10px;
  flex-direction: column;
  position: absolute;
  width: 80%;
  padding: 10px;
  z-index: 1;
  border-radius: 10px;
  left: 27%;
  top: 60%;
}
.judgement-option-button {
  box-shadow: 0 9px 14px rgba(0, 0, 0, 0.2), 0 13px 30px rgba(0, 0, 0, 0.2);
  background-color: #5d54f1;
}
.Filename {
  display: flex;
  gap: 10px;
}
.judgement-progress-bar {
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 20px 20px 10px 20px;
}
.Upload_checkBoxes.disabled,
.createRoom-head.disabled {
  opacity: 0.6; /* Reduced opacity when disabled */
  pointer-events: none; /* Prevents interaction when disabled */
}

.Upload_checkBoxes.disabled {
  opacity: 0.6; /* Reduced opacity when disabled */
  pointer-events: none; /* Prevents interaction when disabled */
}

.createRoom-head.disabled .Judgement_chatRoom_Button {
  /* Additional styling for the button when disabled */
  cursor: not-allowed;
  /* You can add more styles like changing background color, etc. */
}

/* Before it was 768px now 1024px */
@media (max-width: 1024px) {
  .upload-file-contain {
    padding: 20px 15px 20px 15px;
    width: 100%;
  }
  .upload-left-side-vec {
    display: none;
  }
  .upload-right-side-vec {
    display: none;
  }
  .folder_names {
    padding: 0px;
    gap: 1px;
  }
  .folders_data {
    height: 25vh;
  }
  .custom-modal-overlay {
    padding: 10px;
  }
  .chooseFile {
    padding: 20px 0px 15px 0px;
    margin: 5px;
    gap: 20px;
  }
  .upload-file-contain form {
    align-items: normal;
    flex-direction: column;
  }
  .upload-judgement-container {
    flex-direction: column;
    padding: 15px;
  }
  .judgementUploadLeftSide-top {
    width: 100%;
  }
  .right-right-section {
    flex-direction: column;
  }
  .folder_names {
    width: 100%;
  }
  .insider-name {
    height: 15vh;
    width: 100%;
  }
  .fileList {
    width: 100%;
  }
  .uploadJudgementRight {
    width: 100%;
  }
  .upload-files-container h2 {
    font-size: 35px;
  }
  .upload-para p {
    font-size: 17px;
    padding: 8px;
  }
  .head-container p {
    font-size: 17px;
  }
}
