/* Spinner.css */
.spinner-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.spinner-logo {
    width: 50px;
    height: 50px;
    animation: zoom-in-out 1s infinite;
}

@keyframes zoom-in-out {
    0%, 100% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.5);
    }
}
