.app {
  display: flex;
  flex-direction: column;
  /* height: 100vh; */
}

.dashboard-header {
  /* background-color: #f0f0f0; */
  padding: 1.7rem 1rem 1.7rem 1rem;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.search-bar1 input[type="text"] {
  padding: 10px;
  border-radius: 20px;
  border: 2px solid #666666;
  font-size: 16px;
  height: auto;
  width: 100%;
}

.dashboard-searc-icon {
  width: 4%;
  /* background-color: white; */
  display: flex;
  justify-content: center;
  align-items: center;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  height: 38px;
}
.dashboard-main-container {
  /* display: flex; */
  flex: 1;
  width: 100%;
}

aside {
  width: 200px;
}

aside h6 {
  font-size: 16px;
  font-family: raleway;
  font-weight: 300;
  margin: 0;
  text-align: left;
}

aside h1 {
  font-size: 30px;
  font-family: raleway;
  font-weight: 700;
  margin: 0;
  text-align: left;
}
.dashboard-search-container {
  position: relative;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
}
.user-dashboard-sidebar {
  background: linear-gradient(to right, #f0f2f6, #eae3f1);
  padding: 1rem;
  width: 255px;
}

.dashboard-button {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  height: 100%;
  gap: 2rem;
  margin-top: 50px;
}

.dashboard-button button {
  background: transparent;
  color: black;
  font-family: raleway;
  font-weight: 600;
  font-size: 16px;
  padding: 0;
  display: flex;
  align-items: center;
  gap: 10px;
}
.dashboard-button button img {
  height: 25px;
}

.dashboard-button .active {
  background-color: black;
  color: white;
  width: 100%;
  padding: 10px;
}

.cases-dashboard {
  flex: 1;
  background-color: #f8f0ff;
}

.filter-bar {
  display: flex;
  gap: 2rem;
  padding: 1.5rem;
  align-items: center;
  justify-content: space-around;
}

.case-cards {
  display: flex;
  gap: 3rem;
  width: 220vh;
  overflow: auto;
  padding: 1.5rem;
}

.case-card {
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  background: #423e82;
}

.actions {
  display: flex;
  gap: 0.5rem;
  background-color: #e7e0ec;
  padding: 5px;
}
.dashboard-text-content h6 {
  margin: 0;
  padding: 0;
}
.dashboard-text-content h1 {
  margin: 0;
  padding: 0;
  font-size: 25px;
}
.search-bar1 {
  width: 100%;
  /* padding-right: 10%; */
  box-sizing: border-box;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  border: none;
  display: flex;
  align-items: center;
  justify-content: space-around;
  gap: 20px;
}

.dashboard-text-content {
  width: 15%;
}
.search-bar2 {
  width: 100%;
  padding-left: 5px;
  box-sizing: border-box;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  border: none;
  /* background-color: #e7e0ec; */
  height: 38px;
  /* font-weight: 700; */
  /* font-size: larger; */
  font-family: "Raleway";
}

.dashboard-search {
  background: black;
  font-family: raleway;
  font-weight: 600;
  font-size: 16px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1), 4px 4px 8px rgba(0, 0, 0, 0.2);
  margin-left: 20px;
  width: 17%;
  border-radius: 15px;
  height: 40px;
}

.filter-bar select {
  width: 50%;
  height: 40px;
  border-radius: 5px;
  font-family: "Raleway";
  font-weight: 500;
  padding:3px 10px;
}

/* .case-card-section {
  display: flex;
  align-items: baseline;
  text-align: left;
  gap: 31px;
  padding: 15px 15px 0px 15px;
} */

.case-card-right-section {
  background-color: white;
  padding: 10px 10px 0px 10px;
  border-top-left-radius: 10px;
  font-family: mulish;
  position: relative;
}

.case-card-left-section {
  color: white;
  font-family: raleway;
}

.case-card-left-section p {
  color: white;
  font-family: raleway;
  font-weight: 300;
}

.case-card-right-section h3 {
  color: black;
  font-size: 20px;
}
.case-card-left-section h3 {
  font-size: 20px;
}

.dashboard-right-illustration {
  position: absolute;
  top: 62%;
  height: 55%;
  right: -25%;
}

.notificationIcon {
  margin-left: 30px;
  width: 25px;
}

.actions button {
  color: black;
  align-items: center;
  justify-content: space-around;
  display: flex;
  background: none;
  font-weight: 700;
  font-family: "Raleway";
  font-size: 15px;
}

.cases-container {
  display: flex;
  gap: 20px; /* Adds space between the cards */
  padding: 10px;
}

.case-card {
  min-width: 450px;
  flex: 0 0 300px;
  border: 1px solid #ccc;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.case-card-left-section,
.case-card-right-section {
  flex: 1;
}

.actions {
  display: flex;
  justify-content: space-around;
}

.actions button {
  border: none;
  color: black;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.actions button img {
  margin-right: 8px;
}
.case-card {
  display: flex; /* Use flexbox for equal height cards */
  flex-direction: column; /* Ensure children stack vertically */
  grid-template-columns: 1fr 1fr; /* Two equal columns in grid */
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
  align-items: stretch; /* Ensure cards stretch to equal height */
}

.case-card-left-section,
.case-card-right-section {
  display: grid;
  gap: 10px;
  flex: 1; /* Ensure both sections grow equally */
}

.case-card-left-section {
  grid-template-columns: 1fr;
  padding: 10px;
}

.case-card-right-section {
  grid-template-columns: 1fr;
  padding: 15px;
}

.case-card-left-section h3,
.case-card-right-section h3,
.case-card-left-section p,
.case-card-right-section p {
  margin: 0;
  text-align: left;
}

.case-card-left-section h3,
.case-card-right-section h3 {
  font-weight: bold;
}

.case-card-section {
  display: flex;
  flex: 1; /* Ensure this section also stretches */
  position: relative;
}

.actions {
  margin-top: auto;
  /* display: flex; */
  /* justify-content: space-around; */
  padding: 3px;
  position: absolute;
  bottom: -14%;
  width: 100%;
  right: 0;
  left: 0;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
}

.view-toggle {
  margin-left: 20px;
  padding: 8px;
  border: none;
  background: #f0f0f0;
  border-radius: 4px;
}

.notificationIcon {
  margin-left: 20px;
  cursor: pointer;
}

.case-list1 {
  width: 100%;
  max-width: 1600px;
  margin: 0 auto;
  padding: 20px;
}

.case-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* background-color: #fff; */
  /* border: 1px solid #eaeaea; */
  border-radius: 8px;
  margin-bottom: 10px;
  padding: 20px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
}

.case-details1 {
  border-radius: 8px;
  width: 100%;
  margin: 14px 0px auto;
  box-shadow: 0 9px 14px rgba(0, 0, 0, 0.1), 0 13px 30px rgba(0, 0, 0, 0.1);

  /* width: 45%; */
}

/* .case-info1 {
  display: flex;
  width: 100%;
  align-items: baseline;
  justify-content: space-around;
  padding: 20px;
} */

.case-court-info {
  display: flex;
  width: 100%;
  align-items: baseline;
  justify-content: space-around;
  padding: 20px;
  cursor: pointer;
  text-align: center;
  gap: 15px;
}

.case-no-width {
  width: 200px;
}

.case-title-width {
  width: 300px;
}

.case-court-width {
  width: 200px; 
}

.case-status-width {
  width: 200px;
}

.case-info1 h4 {
  font-size: 15px;
  font-weight: 400;
  color: #7d7d7d;
}

.case-item1 p {
  font-size: 16px;
  padding: 0;
  margin: 0;
  font-weight: 600;
}

.dropdown-container {
  position: relative;
  display: inline-block; /* Ensures container fits around the button */
}

.dropdown-button {
  padding: 10px; /* Adjust as needed */
  background-color: #f0f0f0; /* Example background */
  border: none;
  cursor: pointer;
  border-radius: 50px;
}

.dropdown-menu {
  position: absolute;
  top: 100%; /* Position below the button */
  left: 0;
  z-index: 9999;
  background-color: white;
  border: 1px solid #ccc;
  padding: 10px;
  display: block;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  left: -100%;
}

.dropdown-menu button {
  display: block;
  width: 100%; /* Makes the button take the full width of the dropdown */
  padding: 8px 12px;
  text-align: left; /* Align text to the left */
  border: none;
  background: none;
  cursor: pointer;
  color: black;
}

.dropdown-menu button:hover {
  background-color: #f0f0f0; /* Optional: Hover effect */
}

@media (max-width: 768px) {
  .case-info1 {
    flex-direction: column;
  }
  .filter-bar img {
    display: none;
  }
  .filter-bar {
    flex-direction: column;
  }
  .filter-bar select {
    width: 100%;
  }
  .dashboard-search-container {
    flex-direction: column;
    gap: 21px;
  }
  .dashboard-searc-icon {
    display: none;
  }
  .view-toggle {
    width: 100%;
  }
  .search-bar1 {
    flex-direction: column;
  }
  .dashboard-text-content {
    width: 100%;
  }
  .dashboard-search {
    width: 100%;
    margin-left: 0px;
  }
  .notificationIcon {
    display: none;
  }
}
