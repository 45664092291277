/* Preloader.css */
/* Add this CSS to your main CSS file */

/* Preloader.css */
/* Add this CSS to your main CSS file */

.preloader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #5d54f1;
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: transform 5s ease-in-out, opacity 5s ease-in-out;
  flex-direction: column;
  color: white;
}

.preloader.hide {
  transform: translateY(-100%);
  opacity: 0;
}

.loader-content {
  text-align: center;
  position: relative;
}
@keyframes slideIn {
  from {
    transform: translateX(-100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

.newsymbol {
  animation: slideIn 1s ease-in-out forwards;
}

.loader-logo-image {
  width: 200px; /* Adjust size as needed */
  margin-bottom: 20px;
}

.loader-content h1 {
  font-size: 4em;
  margin: 0;
  font-family: mulish;
  font-weight: 700;
}

.loader-below-content {
  margin-top: -80px;
}

.newsymbol {
  position: absolute;
  right: 41%;
  height: 35%;
  top: 9%;
}
.loader-content p {
  font-size: 1.3em;
  margin: 0;
  color: white;
  font-family: raleway;
  font-weight: 300;
}

.loader-wrapper {
  position: relative;
}

.loader-rotation {
  transform: translate3d(0px, 0px, 0px) scale3d(1.25, 1.25, 1) rotateX(0deg)
    rotateY(0deg) rotateZ(-8deg) skew(0deg, 0deg);
  transform-style: preserve-3d;
}

.loader-logo-image {
  position: relative;
  width: 60%;
  opacity: 0.3;
  z-index: -1;
  background: transparent;
}

.loader-logo-top-left,
.loader-logo-top-right,
.loader-logo-bottom-right {
  opacity: 1;
  transform: translate3d(0px, 0%, 0px) scale3d(1, 1, 1) rotateX(0deg)
    rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
  transform-style: preserve-3d;
}

@media (max-width: 768px) {
  .loader-below-content {
    margin-top: 0px;
  }
  .loader-content h1 {
    font-size: 2.8em;
  }
  .loader-logo-image {
    width: 100%;
  }
  .newsymbol {
    position: absolute;
    right: 34.5%;
    height: 26%;
    top: 6.1%;
  }
  .loader-below-content img{
    width: 35%;
  }
}
