.centered-image {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  height: 100vh;
  text-align: left; /* Center-align text */
  padding: 80px 120px 0px 120px;
  position: relative;
}

.searchlawyer-login-form {
  border-radius: 10px;
  display: flex;
  gap: 10px;
}
.searchlawyer-login-form select {
  width: 65%;
  border: none;
  display: flex;
  background-color: #f4f3f3;
  align-items: center;
  justify-content: center;
  height: 35px;
  border: 1px solid black;
  border-radius: 10px;
}

.searchlawyer-login-form .form-group input {
  width: 100%;
  border: none;
  border-radius: 5px;
  display: flex;
  background-color: #f4f3f3;
  align-items: center;
  justify-content: center;
  height: 35px;
  border: 1px solid black;
  border-radius: 10px;
}
.searchlawyer-login-form .form-group input::placeholder {
  font-family: raleway;
  font-weight: 400;
  font-size: 15px;
}

.searchlaywer-content {
  /* display: flex; */
  /* align-items:center; */
  flex-direction: column;
  top: 40%;
  position: absolute;
  /* margin-left: 200px; */
  width: 40%;
  left: 15%;
  /* margin-top: -100px; */
}
.searchlaywer-content h5 {
  font-size: 45px;
  font-family: mulish;
  font-weight: 800;
  color: #000;
}
.searchlawyer-form {
  background: #5d54f1;
  height: 30px;
  width: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 9px 14px rgba(0, 0, 0, 0.2), 0 13px 30px rgba(0, 0, 0, 0.2);
}
.searchlawyer-button {
  display: flex;
}
.mobile-content p {
  margin-top: 15px;
}
.LawyerMobileImage {
  display: none;
}
.SearchLaptopScreen {
  height: 100%;
  width: 100%;
}

@media (max-width: 768px) {
  .SearchLaptopScreen {
    display: none;
  }
  .searchlaywer-content {
    margin-left: 0px;
    width: 100%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .mobile-content p {
    margin-top: 0px;
  }
  .LawyerMobileImage {
    display: block;
  }
  .mobile-content {
    background: url("../images/Findlaywer/mobile-starting-back.png");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }
  .searchlawyer-button {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
  }
  .searchlawyer-login-form {
    margin-top: 30px;
    flex-direction: column;
  }
  .mobileBelowContent {
    display: flex;
    align-items: center;
    justify-self: center;
  }
  .searchlawyer-login-form .form-group input {
    border-radius: 20px;
  }
  .searchlawyer-login-form input[type="text"] {
    padding: 20px 80px 20px 35px;
  }
  .centered-image {
    height: auto;
    padding: 0px;
  }
  .searchlaywer-content {
    margin-top: 80px;
    left: 0;
    padding: 20px;
  }
  .searchlawyer-form {
    padding: 20px 25px 20px 25px;
    width: 100%;
  }
  .searchlaywer-content h5 {
    font-size: 30px;
  }
  .searchlawyer-login-form select {
    border-radius: 17px;
    width: 100%;
    height: 42px;
    padding-left: 24px;
  }
}
