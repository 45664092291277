.contact-us {
  display: flex;
  justify-content: space-between;
  padding: 20px;
  font-family: Arial, sans-serif;
  margin-top: 80px;
}

.contact-form {
  /* background: #e7e5fd; */
  padding: 20px;
  border-radius: 10px;
  /* border: 1px solid #c0c8f0; */
  box-shadow: 15px 13px 12px rgba(0, 0, 0, 0.3);
}
.talk-bubble {
  margin: 10px 12px 10px 70px;
  display: inline-block;
  position: relative;
  height: auto;
  width: 85%;
  padding: 40px 60px 40px 60px;
}
.border {
  border: 8px solid #666;
}
.round {
  border-radius: 30px;
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
}
.content-form {
  width: 60%;
}
.tri-right.border.btm-left:before {
  content: " ";
  position: absolute;
  width: 0;
  height: 0;
  left: -8px;
  right: auto;
  top: auto;
  bottom: -40px;
  border: 32px solid;

  border-color: transparent transparent transparent #666;
}
/* .tri-right.btm-left:after {
  content: " ";
  position: absolute;
  width: 0;
  height: 0;
  left: 0px;
  right: auto;
  top: auto;
  bottom: -20px;
  border: 22px solid #f0f4ff;
  border-color: transparent transparent transparent #f0f4ff;
} */
.contact-form .form-group {
  margin-bottom: 15px;
}

.contact-form .form-group label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
  font-family: raleway;
  font-weight: 600;
}

.contact-form .form-group input {
  width: 100%;
  height: 60px;
  /* padding: 10px; */
  /* border: 1px solid #c0c8f0; */
  /* border-radius: 10px; */
  font-family: raleway;
}

.contact-info {
  /* max-width: 400px; */
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: center; */
}

.contact-info h2 {
  /* color: #000; */
  margin-top: 50px;
}

.laptop-contactus-content {
  width: 70%;
}

.contact-info p {
  align-items: center;
  display: flex;
  justify-content: center;
  font-family: raleway;
  font-size: 16px;
  width: 100%;
  font-weight: 500;
  /* padding-left: 25%; */
}
.contact-info h2 {
  /* align-items: center; */
  justify-content: center;
  font-family: mulish;
  font-weight: 900;
  font-size: 60px;
  /* text-align: center; */
}
.contact-info p {
  /* color: #333; */
}

.message-box {
  background: #000;
  color: #fff;
  padding: 20px;
  border-radius: 30px;
  margin-top: 20px;
  position: relative;
  width: 70%;
  height: 100%;
  box-shadow: 15px 13px 12px rgba(0, 0, 0, 0.3);
}

.message-box label {
  display: block;
  margin-bottom: 10px;
  font-weight: 500;
  margin-left: 12px;
  font-family: raleway;
}
.contact-form input {
  height: 40px;
  border-radius: 10px;
}
.message-box textarea {
  width: 100%;
  padding: 10px;
  border: none;
  border-radius: 15px;
  height: 60%;
  resize: none;
  font-family: raleway;
}

.message-box button {
  /* background: #5d54f1; */
  /* color: #fff; */
  /* padding: 7px 20px;
  border: none;
  border-radius: 10px;
  margin-top: 10px;
  cursor: pointer;
  font-family: raleway;
  font-weight: bold; */
}

.info {
  margin: 10px 12px 10px 70px;
  padding: 20px 0px;
  display: flex;
  flex-direction: column;
  gap: 14px;
}

.info p {
  /* color: #5d54f1; */
  margin: 5px 0;
  font-family: raleway;
  font-weight: 600;
}
.info p img {
  margin-right: 10px;
}

.blueobject {
  position: absolute;
  left: 49%;
  top: 30%;
}
.blackObject {
  position: absolute;
  top: 60%;
  left: 42%;
  z-index: -1;
}
.mobile-contact-us {
  display: none;
}
.mobile-contact-info {
  display: none;
}

@media (max-width: 768px) {
  .contact-us {
    flex-direction: column;
    padding: 10px 30px;
  }
  .contact-form .form-group input {
    height: 35px;
  }
  .blueobject {
    display: none;
  }
  .blackObject {
    display: none;
  }
  .content-form {
    width: 100%;
  }
  .talk-bubble {
    width: 100%;
    margin: 0px;
    padding: 30px;
  }
  .info {
    display: none;
  }
  .laptop-contactus-content {
    display: none;
  }
  .message-box {
    width: 100%;
    margin: 50px;
    padding: 40px;
  }
  .contact-info {
    width: 100%;
  }
  .message-box textarea {
    height: 9rem;
  }
  .mobile-contact-us {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  .mobile-contact-us h5 {
    font-family: mulish;
    font-weight: 700;
    /* font-size: 30px; */
  }
  .mobile-contact-us p {
    text-align: center;
    font-size: 17px;
    font-weight: 500;
    /* margin-left: 50px; */
  }
  .main-mmobile-contact-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .get-in-touch {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .mobile-contact-info {
    display: block;
    background: #5d54f1;
  }
  .mobile-contact-info p {
    margin-bottom: 0px;
  }
  .mobile-contact-info ul {
    margin-bottom: 0px;
  }
  .contact-content {
    padding: 23px;
    font-size: 20px;
    color: white;
    font-family: "Raleway";
    margin: 0px;
  }
  .contact-content li {
    display: flex;
    gap: 20px;
  }
}
