.comingSoon-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-image: url("../images/comingSoon/coming-soon-bakground-min.jpeg"); /* Adjust the path to your image */
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-color: #ffffff; /* Optional: Fallback color if the image doesn't load */
}
.comingsoon-container-contain h1 {
  font-family: mulish;
}

.comingsoon-container-contain {
  text-align: center;
  padding: 50px 0px 0px 0px;
  border-radius: 8px;
}
.coming-soon-upper-section img {
  height: 70px;
  margin: -30px 10px 1px 10px;
}

.input-section img {
  height: 80px;
  z-index: 1;
}
.lawyer-input-section img {
  height: 80px;
  z-index: 1;
}
.input-section input[type="text"] {
  height: 40px;
  border-radius: 5px;
  margin-left: -20px;
  border: none;
  padding-left: 40px;
  align-items: center;
  display: flex;
  justify-content: center;
  font-family: "Raleway";
  font-weight: 600;
}
.lawyer-input-section input[type="text"] {
  height: 40px;
  border-radius: 5px;
  margin-left: -20px;
  border: none;
  padding-left: 40px;
  align-items: center;
  display: flex;
  justify-content: center;
  font-family: "Raleway";
  font-weight: 600;
}
.input-section button {
  margin-left: -10px;
  height: 40px;
  border-radius: 10px;
  width: 30%;
  background: #5d54f2;
  font-family: "Raleway";
  font-size: 16px;
  font-weight: 500;
}
.lawyer-input-section button {
  margin-left: -10px;
  height: 40px;
  border-radius: 10px;
  width: 30%;
  background: #5d54f2;
  font-family: "Raleway";
  font-size: 16px;
  font-weight: 500;
}

.comingsoon-container-contain h1 {
  font-size: 70px;
  font-weight: 1000;
  margin-bottom: 10px;
}

.input-section {
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 57%;
}
.img-content img {
  height: 100%;
  width: 100%;
}

.lawyer-coming-soon-content h3 {
  color: #5d54f1;
  font-family: raleway;
  font-size: 25px;
  font-weight: 600;
}

.lawyer-coming-soon-content ul {
  list-style-type: disc;
  text-align: justify;
  padding-left: 20px;
  margin: 0px 85px;
  font-weight: 500;
}
.coming-soon-lawyer-section {
  margin-top: 50px;
}

.lawyer-input-section {
  margin-top: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.comingsoon-container-contain p {
  font-family: "Mulish";
  font-size: 20px;
  font-weight: 500;
}
.coming-soon-mobile-section {
  display: flex;
  width: 100%;
}
.coming-soon-content-section {
  display: flex;
  justify-content: center;
  padding-top: 20px;
}
.coming-soon-text-content {
  width: 30%;
  padding: 0px 0px 0px 70px;
  display: flex;
  /* align-items: center; */
  justify-content: center;
  flex-direction: column;
}
.coming-soon-text-content h3 {
  color: #5d54f1;
  font-family: Raleway;
  font-weight: 800;
  text-align: left;
  font-size: 25px;
}
.coming-soon-text-content p {
  font-family: Raleway;
  font-size: 15px;
  font-weight: 700;
  text-align: left;
}
.coming-soon-text-content .list-item {
  text-align: left;
  font-family: "Raleway";
  font-weight: 500;
  font-size: 13px;
  justify-content: center;
}
.coming-soon-text-content .list-item ul {
  list-style: disc;
}
.coming-soon-right-section {
  display: flex;
  align-items: center;
  justify-content: center;
}
.loader-divider {
  width: 20%; /* Adjust width as needed */
  background-color: black;
  height: 1.6px;
  margin: 0px auto;
}
@media (max-width: 768px) {
  .comingSoon-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-image: url("../images/comingSoon/mobile-img.jpg"); /* Adjust the path to your image */
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-color: #ffffff; /* Optional: Fallback color if the image doesn't load */
    margin-top: 80px;
  }
  .coming-soon-right-section {
    width: 100%;
    padding: 12px;
  }
  .comingsoon-container-contain h1 {
    font-size: 40px;
    line-height: 80px;
    font-weight: 1000;
    margin-bottom: 0px;
  }
  .input-section {
    flex-direction: column-reverse;
    gap: 20px;
  }
  .input-section button {
    width: 50%;
    margin-right: 13px;
  }
  .coming-soon-mobile-section {
    /* padding: 20px; */
  }
  .input-section img {
    height: 100px;
  }
  .comingsoon-container-contain {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
  }
  .input-section input[type="text"] {
    margin-left: 13px;
  }
  .coming-soon-content-section {
    flex-direction: column;
  }
  .coming-soon-text-content {
    width: 100%;
    padding: 0px 0px 0px 0px;
  }
  .coming-soon-main-content {
    text-align: left;
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .coming-soon-upper-section {
    text-align: left;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 100px;
  }
  .coming-soon-text-content h3 {
    font-size: 20px;
  }
  .input-section {
    width: 100%;
  }
  .img-content {
    display: none;
  }
  .comingSoon-container {
    margin-top: 0px;
  }
  .comingsoon-container-contain {
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
}
