.container1 {
  /* font-family: "Arial", sans-serif; */
  text-align: center;
  /* padding: 20px; */
  display: flex;
  flex-direction: column;
  /* margin-top: 80px; */
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.header h1 {
  /* color: #333; */
  /* margin-bottom: 10px; */
  /* font-family: Mulish; */
  font-weight: 900;
}
.header {
  background-color: transparent;
  /* display: flex; */
  /* flex-direction: column; */
  align-items: center;
  margin-bottom: 0px;
  margin-top: 20px;

  /* 
  hello
  
  hello
  
  */
  width: 80%;
}

.header p {
  /* color: #000; */
  font-weight: 600;
  font-family: mulish;
  /* font-size: 20px; */
  /* border: 3px solid black; */
  /* padding: 10px; */
  /* border-radius: 15px; */
  width: 100%;
}

.courts {
  display: flex;
  justify-content: space-around;
  margin-top: 20px;
}

.court {
  width: 40%;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #f9f9f9;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.court h2 {
  color: #444;
}

.card-stack {
  position: relative;
  width: 270px;
  height: 200px;
}

.card {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}
.card-stack p {
  color: white;
  position: absolute;
  font-weight: 600;
  font-family: raleway;
  font-size: 20px;
}
.background-card2 {
  transition: filter 0.5s ease;
}
.background-card1 {
  transition: filter 0.5s ease;
}
.card-stack {
  position: relative;
  cursor: pointer;
  margin-top: 9px;
}

.court-background-image {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.background-card2 {
  position: relative;
  width: 100%;
  height: 100%;
}

.court-background-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  /* Adjust the transparency here */
  transition: background-color 0.5s ease;
}

@media screen and (max-width: 520px) {
  .court-background-overlay {
    background-color: rgba(0, 0, 0, 0.2);
    transition: none;
  }
}

.foreground-card2 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
  pointer-events: none;
}

.card-content {
  /* color: white; */
  font-size: 1.5em;
  text-align: center;
}

.background-card2:hover .court-background-overlay {
  background-color: rgba(0, 0, 0, 0.3);
}

.background-card2:hover .court-background-image {
  filter: blur(0);
}
.background-card1:hover .court-background-image {
  filter: blur(0);
}
.right-corner {
  right: 3%;
  position: fixed;
  bottom: 0;
  height: 100%;
  max-height: 500px;
}
.court-left-corner {
  left: 3%;
  position: fixed;
  bottom: 0;
  height: 100%;
  max-height: 500px;
}
.court-bottom-image {
  display: flex;
  align-items: center;
  justify-content: center;
}
.center-image {
  position: absolute;
  width: 40%;
  bottom: 0;
  left: 28.8%;
}

.background-card1 {
  /* filter: blur(5px); */
  transform: translate(20px, 20px);
  z-index: 10;
}
.background-card2 {
  /* filter: blur(5px);  */
  transform: translate(-10px, 10px);
  z-index: 10;
}

.foreground-card {
  background: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  z-index: 1;
  /* border-top: 3px solid #5d54f1; */
  /* border-left: 3px solid #5d54f1; */
  transform: translate(10px, 10px);
}

.foreground-card2 {
  /* background: white; */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.7);
  z-index: 1;
  border-top: 3px solid #313134;
  /* Blue border on top */
  border-right: 3px solid #313134;
}

.foreground-card .card-content {
  color: black;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
}

.court-cards {
  display: flex;
  align-items: center;
  gap: 3rem;
  width: 100%;
  justify-content: center;
}
.mobile-heading {
  display: none;
}
.mobileFlags {
  display: none;
}
.main-supreme {
  height: 280px;
  width: 400px;
}

.main-panjab {
  width: 350px;
  height: 250px;
}

@media (max-width: 768px) {
  .courts {
    flex-direction: column;
  }
  .center-image {
    display: none;
  }
  .card-stack {
    width: 237px;
    height: 221px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .court {
    width: 80%;
    margin: 10px auto;
  }
  .court-cards {
    flex-direction: column;
  }
  .header {
    width: 100%;
  }
  .right-corner {
    display: none;
  }
  .court-left-corner {
    display: none;
  }
  .foreground-card {
    transform: translate(0px, 10px);
  }
  .background-card1 {
    transform: translate(15px, 20px);
  }
  .court-cards {
    gap: 2rem;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .mobileFlags {
    position: absolute;
    display: block;
    bottom: -3%;
    width: 100%;
  }
  .card-stack p {
    display: none;
  }
  .background-card2 {
    transform: translate(-5px, 10px);
  }
  .foreground-card2 {
    margin-left: 10px;
  }
  .mobile-heading {
    display: block;
    /* color: #5d54f1 !important; */
    font-weight: 600 !important;
  }
  .court-background-image {
    filter: blur(0px);
  }
  .court-footer p {
    /* text-align: left; */
    font-weight: 400;
  }
  .header {
    padding: 5px;
    margin-top: 50px;
  }
  .supreme-court-card-stack {
    width: 237px;
    height: 221px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .main-supreme {
    height: 221px;
    width: 237px;
  }
}
