.main-container {
  display: flex;
  justify-content: center;
}

.searchBar {
  display: flex;
  justify-content: center;
  padding: 15px;
  width: 85%;
}

.searchBar select {
  margin: 0px 10px;
  padding: 5px;
  align-items: center;
  justify-content: center;
  display: flex;
  border-radius: 10px;
  width: 100%;
  font-family: "Raleway", sans-serif;
}
.searchBar input[type="text"] {
  margin: 0 10px;
  padding: 10px;
  height: 35px; /* Height adjustment */
  font-family: "Raleway", sans-serif;
  border: none;
}

.searchBar input {
  margin: 0 10px;
  padding: 10px;
  height: 35px;
  font-family: "Raleway", sans-serif;
}

.searchBar button {
  margin: 0 10px;
  padding: 5px 30px;
  width: 100%;
  background-color: black;
  border-radius: 15px;
  height: 90%;
  font-family: "Raleway", sans-serif;
  color: white;
  border: none;
  cursor: pointer;
  box-shadow: 0 9px 14px rgba(0, 0, 0, 0.2), 0 13px 30px rgba(0, 0, 0, 0.2);
}

.search-filter-container {
  display: flex;
  align-items: center;
  border: 1px solid #7d7d7d;
  border-radius: 15px;
  width: 100%;
  padding: 5px;
  background-color: #fff;
}

.location-select-container {
  display: flex;
  align-items: center;
  margin-right: 10px;
}

.location-icon {
  width: 20px;
  height: auto;
  margin-right: 8px;
}

.select {
  border: none;
  outline: none;
  background: transparent;
  padding: 5px;
}

.input {
  flex: 1;
  border: none;
  outline: none;
  padding: 5px;
}

.mobile-filter-button {
  display: flex;
  align-items: center;
}

.button,
.filterButton {
  margin-left: 10px;
  padding: 5px 10px;
  border: none;
  outline: none;
  background-color: #5d54f1;
  color: #fff;
  cursor: pointer;
  border-radius: 4px;
}
.filterButton{
    display: none;
}
.search-filter-container1 {
  display: none;
}
.location-select-container1{
    display: none;
}

@media (max-width: 768px) {
  .searchBar {
    flex-direction: column;
    gap: 20px;
  }
  .mobile-filter-button {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .searchBar select {
    width: 100%;
    border-radius: 7px;
  }
  .searchBar input[type="text"] {
    border-radius: 7px;
    margin: 0px;
  }
  .searchBar button {
    width: 40%;
    border-radius: 5px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .filterButton {
    display: block;
  }
  .search-filter-container1 {
    display: block;
    align-items: center;
    border: 1px solid #7d7d7d;
    border-radius: 10px;
    width: 100%;
    padding: 5px;
    background-color: #fff;
  }
  .location-select-container1{
    display: flex;
    align-items: center;
    border-radius: 10px;
    width: 100%;
    background-color: #fff;
  }
  .search-filter-container {
    display: none;
  }
  .filterButton{
    display: block;
}
}
