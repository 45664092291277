body {
  overflow: hidden;
}
@keyframes fade-in-out {
  0%,
  100% {
    opacity: 0;
  }
  10%,
  90% {
    opacity: 1;
  }
}

.custom-box {
  border: 2px solid #ffbc3e;
  padding: 40px;
  margin-top: 30px;
  height: 7rem;
  background-color: black;
  width: 80%;
  margin: 30px auto;
  color: white;
}
.flex-box {
  width: 82%;
  margin: 20px auto;
}

.Landig {
  display: flex;
  flex-direction: column;
  /* align-items: flex-start; */
  /* padding: 30px 0px; */
}

.flex-item i {
  font-size: 24px;
  margin-bottom: 10px;
  color: #042f4c;
}

.container h1 {
  /* color: #ffffff; */
  font-weight: bold;
}

.two-color-text .text-white {
  color: white !important;
}

.two-color-text .text-light {
  color: #ffffcc !important;
}
.Landig {
  width: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
  margin-left: 100px;
}
.custom-btn {
  display: flex !important;
  align-items: center;
  margin: auto;
  padding: 0;
}
.two-para {
  text-align: left;
  line-height: 32px;
  margin-top: 20px;
  font-family: Raleway;
  font-size: 17.1px;
  font-weight: Medium;
  overflow: hidden; /* Ensure any overflow is hidden */
  position: relative;
  font-weight: 400;
  font-size: 20px;
  margin-top: 0px;
  overflow-y: auto; /* Add scrollbar if content overflows */
  padding: 10px; 
  /* Optional: Add padding for better appearance */
  box-sizing: border-box;
}
.two-para {
  margin-top: 0px;
  max-height: 200px;
  overflow-y: hidden; /* Hide the vertical overflow */
  padding: 10px; 
  /* Optional: Add padding for better appearance */
  box-sizing: border-box; /* Ensure padding is included in the height */
  position: relative; /* Ensure the pseudo-element is positioned correctly */
}

.two-para::-webkit-scrollbar {
  display: none;
}

.two-para {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.custom-btn img {
  height: 3.9rem;
  width: auto;
}

.integrated-with .slide {
  margin-top: 10px;
}

.landing-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 30px;
  height: 100vh;
}

.integrated-image-box {
  display: flex;
  align-items: center;
  justify-content: center;
}
.title {
  width: 60%;
  margin: 0px 0px 0px 110px;
  font-size: 20px;
  font-family: raleway;
  font-weight: 600;
  text-align: left;
}
.custom-slider-width {
  width: 40%; /* or any desired width */
  margin: 0 auto; /* center the slider */
}

.letstalk {
  color: "#995a4d";
  font-family: "Open Sans";
  font-weight: "700";
}
.chat-buttons {
  display: flex;
  flex-direction: row;
  gap: 20px;
  margin-top: 4rem;
  /* width: 62%;/ */
  /* border: 5px solid #5d54f1; */
  padding: 0px;
  border-radius: 10px;
}
.talk-with-chat {
  width: 259px;
  height: 66px;
  background-color: #5d54f1;
  border-radius: 20px;
  font-family: "Mulish";
  font-size: 18px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1), 4px 4px 8px rgba(0, 0, 0, 0.2);
}

.title p {
  font-size: 20px;
}

.live-case-track {
  width: 270px;
  height: 66px;
  background-color: #5d54f1;
  border-radius: 20px;
  color: white;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1), 4px 4px 8px rgba(0, 0, 0, 0.2);
  font-family: "Mulish";
  font-size: 18px;
}

.headline {
  font-size: 100px;
  font-weight: 900;
  /* text-align: justify; */
  font-family: Mulish;
  color: #2c5966;
  line-height: 120px;
}

@media (max-width: 1100px) {
  .headline {
    font-size: 80px;
  }

  .recog-img-container {
    gap: 0rem;
    overflow-x: auto;
  }
}

.horizontal-line {
  height: 1px;
  background-color: #d9d9d9;
  width: 85%;
  margin: 0px 3px 0px 16px;
}
.section {
  padding: 60px 125px;
  display: flex;
  justify-content: space-between;
}

.images {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px; /* Adds space between images */
}

.images div {
  flex: 1 1 100px; /* Allows images to grow and shrink */
  max-width: 150px; /* Limits the maximum size of the images */
  text-align: center;
  padding: 10px; /* Adds space around each image container */
}

.images img {
  width: 100%;
  height: auto;
  display: block;
}

.images {
  display: flex;
  gap: 10px; /* Spacing between images */
}

.images img {
  width: auto; /* Maintain original image width */
}

.image-and-text {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: absolute;
  bottom: 0;
  font-family: Raleway;
  text-align: left; /* Center the text horizontally */
}
.integrated {
  position: absolute;
  bottom: 0;
  font-family: Raleway;
  text-align: left; /* Center the text horizontally */
}
.images {
  display: flex;
  flex-direction: row;
  gap: 6rem;
  margin-left: 30rem;
}
.roboimg {
  position: relative;
  width: 100%;
  height: auto; 
  /* Adjust height as needed */
  display: flex;
  /* border: 1px solid red; */
}

.top-overlay-image {
  position: absolute;
  z-index: 2;
  margin-top: 50px; /* Adjust if necessary */
  max-width: 100%;
  left: 50%;
  /* Center the image horizontally */
  transform: translateX(-50%); /* Adjust for true centering */
}

.headline-image {
  position: absolute;
  z-index: -1;
  height: 111%;
  max-width: 100%;
  right: 0; /* Position the image to the right */
  top: -85px; /* Align the image to the top */
}

.Landing {
  background-size: cover;
  background-repeat: no-repeat;
  height: 100vh;
  width: 100%;
}
/* .follow-up-screen {
  position: relative;
  display: flex;
  margin-top: 3rem;
  align-items: center;
} */

/* .paras {
  width: 50%;
} */

.follow-up-screen .overlay-text h3 {
  font-weight: 900;
  font-size: 50px;
  font-family: "Mulish";
}

.main-image1 {
  height: auto; /* Adjust as needed */
  display: block;
}

.overlay-text {
  position: absolute;
  top: 45%;
  /* left: 25%;  */
  transform: translateY(-50%);
  color: black;
  width: 100%;
  padding: 20px 0px 0px 60px;
  box-sizing: border-box;
  line-height: 40px;
  font-size: 27.48px;
  font-weight: 600;
  width: 50%;
  align-items: center;
  justify-content: center;
  display: flex;
}
.overlay-text p {
  font-size: 24px;
  /* font-weight: 600; */
  line-height: 30px;
  margin-top: 25px;
  width: 70%;
}
.bar-slick {
  /* width: 50%; */
}

.content-wrapper {
  margin: 60px 40px;
  padding: 30px;
  /* border: 1px solid red; */
  display: flex;
  flex-direction: column;
  background-color: #f3f3f3;
  text-align: center;
}

.recog-img-container {
  display: flex;
  gap: 5rem;
  justify-content: center;
}

.fade-in-line {
  opacity: 0;
  animation: fadeIn 1s forwards;
}

.fade-in-line:nth-child(1) {
  animation-delay: 1s;
}

.fade-in-line:nth-child(2) {
  animation-delay: 2s;
}

.fade-in-line:nth-child(3) {
  animation-delay: 3s;
}

.fade-in-line:nth-child(4) {
  animation-delay: 3s;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.text-content {
  padding-left: 20px;
  position: relative;
  color: black;
  padding: 3rem 8rem 0 8rem;
  box-sizing: border-box;
  line-height: 40px;
  font-size: 27.48px;
  font-weight: 600;
  /* width: 50%; */
}

.text-content p {
  font-family: raleway;
  font-size: 24px;
  font-weight: 500;
  line-height: 33px;
}

/* for 3rd screen */

.why-choose-us {
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: center;
  background-color: rgba(93, 84, 241, 0.2);
  padding: 50px 50px 50px 50px;
  margin-top: 70px;
  box-shadow: 0 4px 4px -2px rgba(0, 0, 0, 0.5);
}

.why-choose-us h2 {
  font-size: 45px;
  color: #000;
  font-family: Mulish;
  font-weight: 800;
}

.main-heading {
  width: 50%;
  display: flex;
  gap: 1rem;
}
.pillars {
  display: flex;
  justify-content: space-between;
  width: 100%;
  text-align: left;
}

.pillar {
  padding: 20px;
  width: calc(33.333% - 10px);
  margin: 5px;
  position: relative;
  border-right: 1px solid black;
}
.pillar1 {
  padding: 20px;
  width: calc(33.333% - 10px);
  margin: 5px;
  position: relative;
}
.pillar h3 {
  color: #333;
  margin-bottom: 10px;
  font-weight: 600;
}
.pillar1 h3 {
  color: #333;
  margin-bottom: 10px;
  font-weight: 600;
}
.pillar p {
  color: #666;
  font-family: Raleway;
  font-size: 21.6px;
}
.pillar1 p {
  color: #666;
  font-family: Raleway;
  font-size: 21.6px;
}

.pillar1 p {
  color: #666;
  font-size: 21.6px;
  font-family: Raleway;
}

.roboimg {
  position: relative;
  width: 100%; /* Adjust width as necessary */
  display: block;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.landingpage-background-image {
  width: 100%;
  height: auto; /* Adjust height based on your design */
  display: block;
}

.overlay-video {
  position: absolute;
  top: 28.9%;
  width: 51%;
  left: 34%;
  height: 56%;
  object-fit: cover;
  z-index: 1;
  transform: "translateX(-50%)";
}

.verticle-line {
  width: 1px; /* Line width */
  height: 100px; /* Line height */
  background-color: black; /* Line color */
  margin: 20px; /* Space around the line */
}
.top-container {
  position: relative;
  width: 100%; /* or any specific width */
}

.landing-top-image {
  width: 20vw;
  height: auto;
  position: absolute;
  top: 50%;
  left: 25%;
  transform: translate(-50%, -90%);
  z-index: 1;
}

.gif-container {
  position: relative;
}

.overlay-image {
  transform: translate(0%, -103%);
  z-index: 1;
  left: 34%;
  width: 24vw;
  height: auto;
  display: block;
  position: absolute;
  transform: translate(0%, -106%);
  top: -6%;
  left: 37%;
}
.main-img-container {
  position: relative;
  margin-top: -70px;
}

.landingpage-background-image {
  width: 40vw;
  height: auto;
  display: block;
  position: relative;
  transform: translate(0%, 12%);
  top: 10%;
  left: 20%;
}
.laptopRightImage {
  display: block;
}
.mobileRightImage {
  display: none;
}
.title.slick-dots {
  display: none;
}
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.pillar.animate {
  animation: fadeIn 2s ease-out forwards;
}
.main-image1-mobile {
  display: none;
}

.two-button {
  display: flex;
  gap: 20px;
  margin-top: 50px;
  width: 100%;
}

.landing-button {
  width: 100%;
  padding: 10px;
  border-radius: 10px;
  box-shadow: 0 9px 14px rgba(0, 0, 0, 0.2), 0 13px 30px rgba(0, 0, 0, 0.2);
  background: #5d54f1;
}

@media (max-width: 768px) {
  .landing-container {
    display: flex;
    flex-direction: column;
  }
  .Landig {
    margin: 100px 0px 0px 20px;
    width: 100%;
  }
  .headline {
    font-size: 60px;
    line-height: 80px;
  }
  .landingpage-background-image {
    width: 65vw;
  }

  .text-content {
    padding: 1rem;
  }

  .text-content p {
    font-size: 16px;
  }

  .two-para {
    margin-top: 0px;
    /* height: 200px; */
    overflow-y: auto;
    padding: 10px;
    box-sizing: border-box;
  }
  .two-button {
    display: flex;
    flex-direction: column;
    margin-top: 20px !important;
  }
  .headline-image {
    /* width: 56vw; */
    /* position: absolute; */
    z-index: -1;
    height: 210%;
    right: 0;
    top: -6px;
    /* display: none; */
  }

  .roboimg {
    margin: 40px 0;
    height: 30%;
  }

  .landing-top-image {
    width: 35vw;
    transform: translate(-50%, -46%);
  }
  .overlay-image {
    width: 40vw;
    transform: translate(0%, -55%);
  }
  .landingpage-background-image {
    transform: translate(0%, 40%);
  }
  .text-content {
    text-align: center;
    /* padding: 20px; */
  }
  .paras {
    width: 100%;
  }

  .laptopRightImage {
    display: none;
  }
  .mobileRightImage {
    display: block;
  }
  .overlay-text {
    width: 100%;
    top: 30%;
    left:0%;
  }
  .follow-up-screen {
    flex-direction: column;
  }
  .bar-slick {
    width: 100%;
  }
  .overlay-text p {
    font-size: 16px;
    font-weight: 600;
    line-height: 30px;
  }
  .integrated-with .slide {
    /* margin-top: 165px; */
  }
  .Landig {
    padding: 30px 50px 17px 10px;
    margin-bottom: -50px;
  }

  .content-wrapper {
    margin: 10px;
    margin-top: 250px;
    padding: 0px;
  }

  .recog-img-container {
    overflow-x: auto;
    gap: 1rem;
  }
}
@font-face {
  font-family: "tweet";
  /* src: local("Arial"); Use a standard font instead */
  font-weight: normal;
  font-style: normal;
}

/* ... other CSS rules remain the same ... */

.door:before {
  font-family: Arial, sans-serif; /* Use a standard font */
  position: absolute;
  font-size: 16px; /* Adjust size as needed */
  display: block;
  font-weight: 800;
  content: "USE CHATBOT FOR FREE";
  width: 100%;
  text-align: center;
  height: 20px;
  left: 0;
  top: 50%;
  transform: translateY(43%);
}

.door2:before {
  font-family: Arial, sans-serif; /* Use a standard font */
  position: absolute;
  font-size: 16px; /* Adjust size as needed */
  display: block;
  font-weight: 800;
  content: "TRACK YOUR CASES LIVE";
  width: 100%;
  text-align: center;
  height: 20px;
  left: 0;
  top: 50%;
  transform: translateY(43%);
}

.top:before {
  bottom: 5px;
  top: auto;
}

.bottom:before {
  top: 5px;
}

.trapdoor {
  transform: translateZ(0px);
  top: 50%;
  left: 50%;
  width: 100%;
  padding: 10px;
  border-radius: 10px;
  background: transparent;
  height: 50px;
  transition: background 400ms ease-in-out;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  background-color: #e5e4fd;
  box-shadow: 0 9px 14px rgba(0, 0, 0, 0.2), 0 13px 30px rgba(0, 0, 0, 0.2);
}

.trapdoor a {
  text-decoration: none;
  color: black;
  font-family: raleway;
  font-weight: 700;
}

.trapdoor:hover .door {
  box-shadow: 0px 0px 10px -2px rgba(0, 0, 0, 0.4);
  transform: scale(1.08);
}

.trapdoor:hover .top {
  top: -50%;
}

.trapdoor:hover .bottom {
  top: 100%;
}

.trapdoor .twitter-follow-button {
  margin-left: -77px;
  position: absolute !important;
  margin-top: -14px;
  left: 50%;
  top: 50%;
}

.top {
  top: 0%;
  left: 0px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.top:before {
  top: 5px;
}

.bottom {
  top: 50%;
  left: 0px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

.bottom:before {
  top: -20px;
}

.door {
  transition: top 400ms, box-shadow 200ms, transform 300ms;
  transition-timing-function: ease-in-out;
  background-color: #5d54f1;
  position: absolute;
  overflow: hidden;
  width: 100%;
  z-index: 2;
  height: 50%;
}
.door2 {
  transition: top 400ms, box-shadow 200ms, transform 300ms;
  transition-timing-function: ease-in-out;
  background-color: #5d54f1;
  position: absolute;
  overflow: hidden;
  width: 100%;
  z-index: 2;
  height: 50%;
}

@media (max-width: 425px) {
  .headline {
    font-size: 45px;
    line-height: 80px;
  }

  .content-wrapper {
    margin: 100px 0 70px;
    padding: 0px;
  }

  .roboimg {
    height: 20%;
  }
}
