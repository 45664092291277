.notification-dropdown-container {
  position: absolute;
  right: 0;
  width: 350px;
}

.notification-dropdown-menu {
  position: absolute;
  right: 60px;
  top: 100%;
  background-color: white;
  width: 100%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  overflow: hidden;
  z-index: 100;
}

.notification-header {
  padding: 10px 10px;
  /* background-color: #5d54f1; */
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: bold;
}
.notification-header p {
  margin-bottom: 0px;
  /* color: white; */
  font-weight: 500;
  font-size: 13px;
  cursor: pointer;
}
.notification-header h5 {
  font-family: "Mulish";
  font-size: 20px;
  margin: 0px;
  /* color: white; */
}

.notification-content {
  max-height: 300px;
  overflow-y: auto;
}

.notification-item {
  display: flex;
  align-items: center;
  /* border-bottom: 1px solid #eee; */
  padding: 10px;
}
.notification-item {
  transition: transform 0.2s ease, box-shadow 0.5s ease;
  cursor:default;
}

.notification-item:hover {
  transform: scale(1.02); /* Scale up the element */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Add a shadow for more emphasis */
}

.notification-icon {
  width: 40px;
  height: 40px;
  background: url("../images/notification/notification.svg") no-repeat center
    center;
  background-size: contain;
  margin-right: 10px;
}
.close-button {
  font-size: small;
  font-weight: 700;
  background: transparent;
  color: black;
}

.notification-info {
  flex-grow: 1;
}

.notification-title {
  font-size: 14px;
  /* color: #333; */
}

.notification-time {
  font-size: 12px;
  color: #666;
}

.notification-footer {
  text-align: center;
  padding: 10px;
  background-color: #f9f9f9;
}

.notification-footer button {
  padding: 5px 10px;
  background-color: #5d54f1;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-family: mulish;
}

.notification-categories {
  display: flex;
  gap: 60px;
  padding: 10px 15px;
  /* border-bottom: 1px solid #ddd; */
  font-family: mulish;
}

.notification-categories button {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 14px;
  /* color: #5d54f1; */
}

.notification-categories .active {
  font-weight: bold;
}

.notification-content {
  height: 40vh;
  /* overflow-y: scroll; */
  overflow-y: auto;
}
.notification-content::-webkit-scrollbar {
  display: none;
}

.notification-content {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.notification-item {
  display: flex;
  align-items: center;
  padding: 10px;
  /* border-bottom: 1px solid #ddd; */
}

.notification-info {
  margin-left: 10px;
  flex-grow: 1;
}

.notification-title {
  font-size: 15px;
  margin: 0;
  font-weight: 600;
  font-family: Mulish;
}

.notification-time {
  font-size: 12px;
  color: #777;
  font-family: mulish;
  font-weight: 500;
}

.notification-footer {
  padding: 10px;
  text-align: center;
}

.close-button {
  background: none;
  border: none;
  font-size: 18px;
  cursor: pointer;
  color: #333;
}
.no-notifications {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 50vh;
}
.no-notifications h4 {
  text-align: center;
  font-family: mulish;
  font-weight: 600;
  color: #7d7d7d;
  font-size: 18px;
}
.no-notifications p {
  font-family: mulish;
  font-weight: 800;
  font-size: 18px;
  color: black;
}

@media (max-width: 768px) {
  .notification-dropdown-container {
    right: 20px;
  }
  .notification-dropdown-menu {
    height: 70vh;
  }
}
