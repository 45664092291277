html,
body {
  overflow-y: auto;
}
.feature-container {
  display: flex;
  margin-bottom: 6rem;
}

.feature-sidebar {
  width: 30%;
  padding: 0px 20px 0px 0px;
  display: flex;
  flex-direction: column;
}

.feature-btn {
  background-color: transparent;
  border: none;
  padding: 0px 0px 0px 50px;
  width: 100%;
  text-align: left;
  font-size: 20px;
  height: 60px;
  cursor: pointer;
  color: #7d7d7d;
  font-family: "Mulish";
  margin-bottom: 10px;
  font-weight: bold;
  /* border-top-right-radius: 10px; */
  /* border-bottom-right-radius: 10px; */
  position: relative;
}

.feature-content {
  flex-grow: 1;
  width: 70%;
  background-color: #f3f3f3;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
  border-radius: 15px;
  display: flex;
}
.feature-main-header {
  padding: 20px 0px 0px 75px;
  width: 70%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* height: 100%; */
}
.feature-image {
  width: 35%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.feature-content img {
  max-width: 100%;
  /* height: 100%; */
}
/* .feature-image-chatbot, .feature-image-livecase, .feature-image-document, .feature-image-expertLawyer{
  height: 100%;
} */
.feature-content p {
  color: black;
  font-family: raleway;
}

.product-feature h2 {
  font-weight: 900;
  /* font-size: 45px; */
  /* color: #000; */
  font-family: Mulish;
  /* margin: 3rem; */
}
.feature-main-header h2 {
  font-size: 28px;
  color: black;
  font-family: raleway;
  font-weight: 600;
  margin: 15px 0px 20px 0px;
}
.subtitle {
  font-size: 20px;
  color: black;
  font-family: raleway;
  font-weight: 600;
  margin: 0rem 15rem 1rem 0rem;
}
.feature-main-header ul {
  padding: 0px 87px 0px 30px;
  font-size: 16px;
  font-family: "Raleway";
  font-weight: 400;
  list-style: unset;
}

.feature-main-header li {
  margin-bottom: 10px; /* Adjust as needed for spacing */
}
.feature-talk-with-chat {
  width: 202.99px;
  height: 48.47px;
  background-color: #5d54f1;
  border-radius: 20px;
  font-family: "Mulish";
  font-size: 18px;
  margin-bottom: 20px;
  border: none;
  color: white;
  box-shadow: 0 9px 14px rgba(0, 0, 0, 0.2), 0 13px 30px rgba(0, 0, 0, 0.2);
}
.feature-talk-with-chatbot {
  width: 202.99px;
  height: 48.47px;
  background-color: #5d54f1;
  border-radius: 20px;
  font-family: "Mulish";
  font-size: 18px;
  margin-bottom: 20px;
  border: none;
  color: white;
  box-shadow: 0 9px 14px rgba(0, 0, 0, 0.2), 0 13px 30px rgba(0, 0, 0, 0.2);
}
.feature-sidebar {
  display: flex;
  flex-direction: column;
  gap: 20px; /* Adjust the spacing between buttons as needed */
}

.feature-btn.selected {
  background-color: #1d1d1d;
  color: white;
}

.feature-arrow {
  display: none;
  position: absolute;
  right: 10px; /* Adjust as needed to position at the rightmost side */
  top: 50%;
  transform: translateY(-50%);
  width: 10px; /* Adjust size as needed */
  height: auto;
}
.feature-btn:hover {
  background-color: #1e1e21;
  color: white;
}
.feature-btn:hover .feature-arrow {
  display: inline-block;
}
.feature-btn.selected .feature-arrow {
  display: inline-block;
}

.feature-content {
  position: relative;
}

.feature-content img {
  max-width: 100%;
}

.feature-sidebar {
  overflow-y: auto;
}

.feature-sidebar .feature-btn .titleAndImg {
  display: flex;
  align-items: center;
  /* justify-content: space-between; */
  /* width: 250px; */
  padding: 10px;
  gap: 10px;
}
.mobile-description {
  display: none;
}
.desktop-description {
  display: block;
}
.feature-sidebar .feature-btn.selected {
  background-color: #1e1e21;
}

.ourservices-upper-content {
  display: flex;
  align-items: baseline;
  justify-content: flex-start;
  gap: 27px;
}

@media (max-width: 768px) {
  .product-feature h2 {
    /* margin: 3rem 0rem 1rem 0rem; */
    /* font-size: 38px; */
  }
  .mobile-description {
    display: block;
  }
  .desktop-description {
    display: none;
  }

  .feature-main-header h2 {
    margin: 1rem 0rem 1rem 2rem;
    font-size: 20px;
    display: none;
  }
  .feature-content p {
    margin: 0rem 1rem 1rem 2rem;
  }
  .feature-sidebar {
    width: 100%;
  }
  .feature-main-header ul {
    padding: 0px 20px 0px 60px;
    list-style: disc;
  }
  .feature-button {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
  .feature-image {
    background-color: transparent;
  }
  .feature-sidebar .feature-btn .titleAndImg {
    width: max-content;
    padding: 5px 10px 5px 10px;
  }
  .feature-btn {
    padding: 0;
    height: auto;
  }
  titleAndImg {
    display: flex;
    flex-direction: revert;
    width: 216px;
  }

  .feature-container {
    display: flex;
    flex-direction: column;
  }
  .feature-sidebar {
    display: flex;
    flex-direction: row;
    padding: 10px;
  }
  .feature-content {
    display: flex;
    flex-direction: column;
  }
  .subtitle {
    margin: 0rem;
  }
  .feature-content {
    box-shadow: none;
    background: transparent;
    padding: 0px;
    width: 100%;
    align-items: center;
  }
  .feature-main-header {
    padding: 0px;
    width: 100%;
  }
  .feature-image {
    width: 60%;
  }
  .feature-talk-with-chatbot {
    width: 100%;
    height: 45px;
    background-color: #5d54f1;
    border-radius: 10px;
    margin-bottom: 0px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2), 0 12px 40px rgba(0, 0, 0, 0.2); /* Enhanced box shadow on hover */
  }
  .feature-talk-with-chat {
    /* width: 200px; */
    height: 35px;
    background-color: #5d54f1;
    border-radius: 10px;
    margin-bottom: 0px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2), 0 12px 40px rgba(0, 0, 0, 0.2);
  }
  .feature-btn.selected .feature-arrow {
    display: none;
  }
  .titleAndImg .index {
    display: none;
  }
  .feature-btn {
    height: max-content;
    width: max-content;
    font-size: 15px;
    border-radius: 10px;
  }
}
