.high-court-case-detail-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 72px;
}
.case-detail-img-text h1 {
  font-size: 40px;
  font-family: Mulish;
  /* color: black; */
  font-weight: 800;
}
.case-detail-img-text {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5rem;
  margin-top: 60px;
}
.case-detail-container {
  display: flex;
  flex-direction: row;
  border: 1px solid black;
  background-color: white;
  height: 100%;
  max-height: 160px;
  border-radius: 0px 0px 0px 0px;
  border-top: 15px solid black;
  width: -webkit-fill-available;
}
.court-section {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 40px;
  width: 100%;
  max-width: 250px;
}
.court-section:not(:last-child)::after {
  content: "";
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  width: 0.5px;
  background-color: black; /* Border color between sections */
}
.case-detail-containers {
  display: flex;
  gap: 20px;
  align-items: center;
  justify-content: center;
  padding: 50px;
}
.discuss-and-button {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.discuss-and-button p {
  margin-bottom: 0px;
  font-family: raleway;
  font-weight: 500;
  font-size: 16px;
  margin-left: 10px;
}
.discuss-and-button .court-buttons {
  flex-direction: row;
  display: flex;
  gap: 20px;
  margin-top: 20px;
  height: 50px;
}

.Propertyname {
  color: #5d54f1;
  font-weight: 500;
}

.court-buttons button {
  /* width: 300px; */
  /* border-radius: 20px; */
  /* color: white; */
  /* box-shadow: 0 9px 14px rgba(0, 0, 0, 0.2), 0 13px 30px rgba(0, 0, 0, 0.2); */
}

.high-court-info-text {
  flex-direction: row;
  display: flex;
}
.gradient-button {
  background-color: #5d54f1;
  color: white; /* Text color */
  padding: 10px 20px; /* Padding around the text */
  border: none; /* No border */
  border-radius: 20px; /* Rounded corners */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Subtle shadow */
  font-weight: bold; /* Bold font */
  cursor: pointer; /* Cursor changes to pointer when hovered */
  transition: all 0.3s ease; /* Smooth transition for hover effects */
  text-align: center; /* Center the text */
  display: inline-block; /* Required for width and center alignment */
}
.case-detail-img-text img {
  cursor: pointer;
  position: absolute;
  left: 3%;
}
.highCourt-order-summary-header {
  background-color: #000;
  color: #fff;
  padding: 10px;
  border-radius: 8px 8px 0 0;
  text-align: left;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
}

.highCourt-order-summary-header p {
  color: white;
  font-family: raleway;
  margin-bottom: 0px;
  padding: 10px 0px;
}
.high-court-info-text {
  align-items: flex-start;
}
.chatButton {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.chatButton p {
  font-family: raleway;
  font-size: 18px;
  /* color: #5d54f1; */
  font-weight: 600;
  margin-top: 20px;
}

.panjab-case-info {
  height: 100px;
  max-height: 262px;
  width: 430px;
  max-width: 550px;
}

@media (max-width: 768px) {
  .case-detail-containers {
    flex-direction: column;
    padding: 20px;
  }
  .court-section {
    padding: 15px;
  }
  .gradient-button {
    padding: 0px;
    width: 100%;
    height: 100%;
  }
  .chatButton {
    padding: 0px 20px 0px 20px;
  }
  .case-detail-img-text h1 {
    font-size: 20px;
  }
  .case-detail-img-text img {
    height: 35px;
  }

  .case-detail-img-text {
    gap: 1rem;
  }
  .discuss-and-button {
    padding: 0px 10px 0px 10px;
  }
  .court-buttons button {
    padding: 10px;
    font-size: 18px;
    font-weight: 500;
    font-family: "Raleway";
  }
  .discuss-and-button .court-buttons {
    width: 100%;
    flex-direction: column;
    height: 100%;
    padding: 20px;
    display: flex;
    align-items: center;
  }
  .highCourt-order-summary-header {
    flex-direction: column;
  }
  .chatButton p {
    padding: 0px 20px 0px 20px;
  }
}
