/* SmallLoader.css */
.small-loader {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.loader {
  border: 4px solid #3498db; /* Border color */
  border-top: 4px solid #ff6347; /* Color inside the round loader */
  margin-top: 10px;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); /* Subtle box shadow for depth */
  animation: spin 1s linear infinite, changeColor 5s infinite alternate;
}

.loading-text {
  margin-top: 8px;
  font-size: 12px;
  color: white;
  text-align: center; /* Ensure text is centered */
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

@keyframes changeColor {
  0% { border-color: #ff6347; /* Initial border color */ }
  100% { border-color: #3498db; /* Final border color */ }
}

.small-loader:hover .loader {
  transform: scale(1.2); /* Scale up on hover */
  filter: brightness(1.2); /* Adjust brightness on hover */
}

@keyframes pulse {
  0% { transform: scale(1); }
  50% { transform: scale(1.2); }
  100% { transform: scale(1); }
}

.loader:hover {
  animation: spin 1s linear infinite, pulse 2s ease-in-out infinite; /* Add pulse effect on hover */
}
