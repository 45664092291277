.sidebar {
  width: 17rem;
  /* height: calc(100vh - 8rem); */
  height: 100vh;
  /* background: #f9f9f9; */
  position: fixed;
  top: 2.4rem;
  /* left: 1rem; */
  /* border-radius: 10px; */
  transition: left 0.3s ease;
  z-index: 1;
  /* margin: 10px 0px 0px 0px; */
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
  scroll-behavior: smooth;
}
.all-histroy {
  height: calc(100vh - 15rem);
  overflow-y: scroll;
}

.sidebar-list {
  list-style: none;
  padding: 0;
  margin: 60px 0 0 12px;
}
.toggle-btn {
  cursor: pointer;
  padding: 10px;
  background-color: #444;
  color: white;
  position: absolute;
  top: 0;
  left: 0;
  display: none;
}

.toggle-btn i {
  font-size: 20px;
  color: #000;
}
.chat-button {
  color: #000;
  font-size: 20px;
  font-weight: 600;
  padding: 5px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
  display: flex;
  gap: 15px;
  align-items: center;
  width: 90%;
  background-color: #ececec; /* 15% opacity */
  font-family: sans-serif, raleway;
  /* max-width: 200px; */
  box-sizing: border-box;
}
.sidebar.open {
  left: 0;
}
.text-light::-webkit-scrollbar {
  width: 0;
}

.chat-button1:hover {
  /* background-color: #5d54f1; */
  /* background: #ececec; */
  /* color: #0d0d0d; */
  /* background: #f3f3f3; */
  /* color: black; */
}

.chat-button1 {
  width: 95%;
  text-align: left;
  color: #0d0d0d;
  padding: 5px 10px;
  font-family: sans-serif, "Raleway";
  font-size: 14px;
  background: #f9f9f9;
}

.text-light {
  scrollbar-width: none;
}
.chat-button i {
  margin-right: 8px;
}
.icon-box {
  background-color: transparent;
}

.icon-box i {
  color: #fff;
}
h6 {
  margin-left: 14px;
  font-size: 14px;
  font-family: "Poppins";
  font-weight: 300;
}
.btn.text-light.mt-2.ml-0 {
  font-size: 12px;
  font-family: "Poppins";
  font-weight: 200;
}

.btn {
  font-size: 12px;
  font-family: "Poppins";
  font-weight: 200;
}

.sidebar-logout-button {
  display: flex;
  align-items: center;
  background-color: transparent;
  border: none;
  /* color: #5d54f1; */
  color: #0d0d0d;
  font-size: 16px;
  cursor: pointer;
  padding: 10px;
  font-family: sans-serif, raleway;
  font-weight: 600;
  font-size: 18px;
  gap: 10px;
  margin-left: 10px;
}

.laptop-sidebar-logout-button {
  display: flex;
  align-items: center;
  background-color: transparent;
  border: none;
  /* color: #5d54f1; */
  color: #0d0d0d;
  cursor: pointer;
  font-family: raleway;
  font-weight: 600;
  font-size: 18px;
  gap: 60px;
  margin-left: 10px;
}

.laptop-sidebar-logout-button .text {
  padding: 5px 10px;
  border-radius: 7px;
}

.laptop-sidebar-logout-button .text:hover {
  background: #ececec;
}

.logout-button {
  display: flex;
  gap: 1rem;
}

.btn.dropdown-toggle {
  position: relative; /* Relative position for the dropdown alignment */
  z-index: 2; /* Higher index so it's clickable over other elements */
}

.dropdown-menu {
  position: absolute;
  top: 100%; /* Position it below the toggle button */
  right: 0; /* Align to the right edge of the parent container */
  background-color: #fff;
  border: 1px solid #ccc;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  width: auto; /* Adjust based on content size */
  display: none; /* Initially hidden */
  z-index: 1; /* Ensure it's above other content but below the toggle for interaction */
}

.show {
  display: block;
}
.mobile-sidebar {
  padding: 20px 0px 0px 0px;
}
.chatroom-mobile-container {
  display: none;
}
/* .selected {
  background-color: #5d54f1;
  color: white;
} */
.all-history {
  height: calc(100vh - 17.5rem);
  overflow-y: scroll;
  position: relative;
}
.mobile-home-button {
  display: none;
}

/* new chat */

.options-toggle-button {
  margin-left: 10px;
  background: none;
  border: none;
  font-size: 18px;
  margin-top: -8px;
  color: black;
  cursor: pointer;
}

.chat-button-container {
  position: relative;
}

.chat-options {
  display: flex;
  gap: 10px;
  flex-direction: column;
  position: absolute;
  top: 23px;
  width: 50%;
  /* background: rgba(44, 89, 102, 0.15);
  border: 1px solid #ccc; */
  padding: 10px;
  z-index: 1;
  right: 3px;
}

.chat-button1 {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.option-button {
  background: #ececec;
  color: #0d0d0d;
  font-family: sans-serif, raleway;
}

.chat-button1.selected {
  /* background-color: #5d54f1; */
  background-color: #ececec;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@media (max-width: 768px) {
  .sidebar {
    height: -webkit-fill-available;
    top: 0rem;
    width: 100%;
    margin: 0rem;
    left: -18rem;
  }

  .toggle-btn {
    top: 1.2rem;
  }
  .chat-button {
    background: transparent;
    /* justify-content: flex-end; */
    flex-direction: row-reverse;
    width: 100% !important;
    /* color: #5d54f1; */
    color: #0d0d0d;
    position: absolute;
    right: 0;
  }
  .cnr_number {
    height: 40vh;
    padding: 30px 20px;
    overflow-y: scroll;
  }

  .mobile-sidebar {
    padding: 20px 0px 0px 0px;
    border-radius: 5px;
  }
  .chatroom-mobile-container {
    display: block;
    padding: 60px 0px 0px 0px;
  }
  .chatroom-button {
    padding: 10px 20px;
    font-size: 18px;
  }
  .chatroom-mobile-container h4 {
    margin: 0px;
    font-family: sans-serif, raleway;
    font-weight: 600;
    /* color: #5d54f1; */
    color: #0d0d0d;
    padding: 0px 0px 0px 20px;
  }
  .mobile-all-history {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
    background: transparent;
    color: black;
    align-items: baseline;
    padding: 15px;
    height: calc(100vh - 20rem);
    overflow-y: scroll;
  }

  .mobile-all-history button {
    background: none;
    color: black;
    font-family: sans-serif, "Raleway";
    font-size: 19px;
    padding: 5px 20px;
    background: #ebebeb;
    width: 96%;
    text-align: left;
  }
  .selected {
    /* background: #5d54f1 !important; */
    background-color: #f9f9f9;
  }
  .chat-button {
    display: none;
  }
  .mobile-home-button {
    gap: 13px;
    display: flex;
    position: relative;
    /* right: 5%; */
    justify-content: space-between;
    /* flex-direction: row-reverse; */
    top: 3%;
    align-items: center;
  }
  .chatHistory-toggle {
    margin-left: 5px;
  }

  .profile-and-home {
    display: flex;
    gap: 42%;
    position: relative;
    right: 15%;
  }
}

@media (max-width: 1000px) {
  .sidebar {
    left: -50rem;
  }

  .toggle-btn {
    display: block;
  }

  .sidebar.open {
    left: 0;
  }
  .toggle-btn {
    display: none;
    cursor: pointer;
    padding: 10px;
    background-color: transparent;
    color: black;
    position: fixed;
    top: 5rem;
  }
  .chat-button {
    width: 100%;
    margin-left: 0;
    font-size: 21px;
    font-family: sans-serif, raleway;
  }
  .mobile-history-side-content {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  .laptop-sidebar-logout-button {
    display: none;
  }
}

@media (max-width: 768px) {
  .laptop-history {
    display: none;
  }
}
