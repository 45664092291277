.supreme-court-case-details,
.important-dates {
  width: 50%;
}

.supreme-court-case-details {
  /* border-radius: 8px; */
  overflow: hidden;
  /* width: 90%;  */
}

.supreme-court-case-details {
  overflow: hidden;
  margin: 14px 0px auto;
  width: 100%;
  border-left: 1px solid #000;
  border-right: 1px solid #000;
  border-top: 25px solid #1e1e21;
  border-bottom: 1px solid black;
}
.supreme-court-case-details th:first-child,
.supreme-court-case-details td:first-child {
  border-right: 1px solid #000;
}
.supreme-court-case-details th:second-child,
.supreme-court-case-details td:second-child {
  border-right: 1px solid #000;
  color: white;
  /* font-family: raleway; */
  font-weight: bold;
  font-size: 15px;
}
.supreme-court-case-details th::before {
  content: "→";
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  transition: opacity 0.3s;
}
/* .supreme-court-case-details th:hover th::before {
  opacity: 1;
}
.supreme-court-case-details th:first-child:hover {
  color: white;
}
.supreme-court-case-details td:first-child:hover,
.supreme-court-case-details th:first-child:hover {
  color: white;
} */

.supreme-court-case-details table {
  width: 100%;
  border-collapse: collapse;
  height: 100%;
  min-height: 400px;
}

.supreme-court-case-details th,
.supreme-court-case-details td {
  text-align: left;
  padding: 15px 20px;
  color: #4c4c4c;
  /* font-family: raleway; */
  font-size: 15px;
  /* font-weight: 600; */
}

.supreme-court-case-details td {
  /* background-color: #f9f9f9; */
}
.supreme-court-case-details th {
  font-weight: bold;
  /* color: #5d54f1; */
  width: 50%;
}

.supreme-court-case-detail-containers {
  display: flex;
  gap: 20px;
  align-items: center;
  justify-content: center;
  padding: 30px 60px 30px 60px;
}

.supreme-court-table-with-vector {
  width: 100%;
}

.supreme-court-case-contain {
  display: flex;
  gap: 0px;
}

@media (max-width: 768px) {
  .supreme-court-case-detail-containers {
    flex-direction: column;
  }
}
