.sidebar2 {
  width: 18%;
  height: calc(100vh - 9rem);
  background: #000;
  position: fixed;
  top: 5.4rem;
  right: 0%;
  border-top-right-radius: 10px; /* Rounds the top right corner */
  border-bottom-right-radius: 10px;
  transition: left 0.3s ease;
  z-index: 100;
  overflow: auto;
  margin: 20px;
}
.cnr_number {
  margin-top: 50px;
  overflow: scroll;
  height: 30vh;
  font-size: 20px;
}
.case-item1 p {
  font-size: 14px;
}
.cnr-numbers p {
  font-weight: 600;
  color: white;
  padding: 18px 0px 0px 20px;
  font-size: 20px;
  margin-bottom: 0px;
}
.cnr-numbers hr {
  border: none;
  height: 2px;
  background-color: white;
}
.case-item1 {
  display: flex;
  gap: 17rem;
  min-height: 3rem;
  text-align: left;
  font-family: raleway;
}
input:focus {
  outline: none;
  border-color: #595f84;
}
.toggle-btn1 {
  cursor: pointer;
  padding: 10px;
  background-color: #444;
  color: white;
  position: absolute;
  top: 0;
  right: 0;
  display: none;
}

.toggle-btn1 i {
  font-size: 20px;
}
.person-container {
  display: flex;
  margin: 2rem;
}
.person-name {
  font-family: Poppins;
  font-weight: 500;
  color: white;
  margin-top: 30px;
  margin-left: 17px;
  font-size: 1.2rem;
  word-wrap: break-word;
  max-width: 70%;
  color: white;
}
.addcase {
  background: transparent;
  border: 1px solid white;
  border-radius: 5px;
  height: auto;
  width: 85%;
  color: white;
  font-size: 1.3rem;
  font-family: "Poppins";
}
.current {
  color: white;
  background: #2c3141;
  padding: 8px;
  border-radius: 10px;
  font-family: Poppins;
  width: 88%;
  font-size: 20px;
  font-family: Poppins;
}

.cases {
  max-height: 300px;
  overflow-y: auto;
  overflow-x: hidden;
  box-sizing: border-box;
  height: calc(100vh - 27rem);
}

.cases {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.case1 {
  background: #2c3141;
  border-radius: 20px;
  font-family: "Poppins";
}
.progress1 {
  border-radius: 5px;
  border: none;
  background-color: #73925f;
  margin-top: px;
}
.pending {
  border-radius: 5px;
  border: none;
  background-color: #925f5f;
  margin-top: -2px;
}
.next {
  font-size: 11px;
  color: gray;
}
.sidebar2::-webkit-scrollbar {
  display: none;
}
.sidebar2 {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.chatroom-button {
  background-color: #ffffff;
  color: black;
  width: 100%;
  height: 40px;
  text-align: left;
  /* padding: 0px 0px 0px 20px; */
  font-family: "Raleway";
  font-weight: 500;
  font-size: 15px;
  border-radius: 5px;
}

@media (max-width: 1500px) {
  .toggle-btn1 {
    display: block;
    cursor: pointer;
    padding: 10px;
    background-color: transparent;
    color: black;
    position: fixed;
    top: 5rem;
    right: 0rem;
  }
  .sidebar2 {
    right: -60rem;
    width: 23%;
  }
  .sidebar2.open {
    right: 0;
  }
}

@media (max-width: 768px) {
  .sidebar2 {
    right: -30rem;
    width: 60%;
  }

  .toggle-btn1 {
    display: block;
  }

  .sidebar2.open {
    right: 0;
  }
  .toggle-btn1 {
    display: block;
    cursor: pointer;
    padding: 10px;
    background-color: transparent;
    color: #000;
    position: fixed;
    top: 5rem;
    right: 0rem;
    display: none;
  }
  .cases {
    height: calc(100vh - 29rem);
  }
}
