.privacy-header {
  margin-top: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: black;
  font-family: raleway;
}
.privacy-header h3 {
  color: black;
  font-weight: 800;
  font-size: 45px;
}
.privacy-header p {
  font-family: mulish;
  font-weight: 700;
  /* border: 2px solid #5d54f1; */
  /* padding: 12px; */
  border-radius: 10px;
}

.privacy-main-contain {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  /* background: linear-gradient(to bottom, #e5e4fd 0%, white 50%); */
}

.privacy-contain-container {
  width: 80%;
  padding: 40px;
  border-radius: 10px;
  /* font-family: ra; */
  background-color: white;
  box-shadow: 0 0 14px rgba(0, 0, 0, 0.2), 0 0 30px rgba(0, 0, 0, 0.2);
  position: relative;
}

.privacy-contain-container h1 {
  font-family: mulish;
  font-size: 26px;
  /* color: #5d54f1; */
  font-weight: 700;
}

.privacy-contain-container p {
  /* margin-top: 20px; */
  font-family: mulish;
  font-size: 15px;
  font-weight: 700;
}

.privacy-main-section ul {
  list-style: disc;
  padding: 0px 0px 0px 35px;
  font-weight: 600;
}

.privacy-main-contain img {
  width: 10%;
  position: absolute;
  right: -3%;
  top: -2%;
}
