.searchOption label {
  font-weight: 400;
  font-size: 15px;
  margin-bottom: 0px;
  display: flex;
  gap: 10px;
  align-items: center;
}
.searchOption h4 {
  font-family: mulish;
  font-size: 20px;
  font-weight: 600;
}
.searchOption {
  display: flex;
  flex-direction: column;
}
.firstHalf {
  margin: 10px 0px 0px 0px;
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.secondHalf {
  margin: 10px 0px 0px 0px;
}
.horizontalLine {
  border: none;
  border-top: 1px solid #000;
  margin: 0px 0;
}
.section-horizontalLine {
  border: none;
  border-top: 1px solid #000;
  margin: 0px 0;
}
.sidebar-search {
  border-bottom-left-radius: 10px;
  border-top-left-radius: 10px;
}
.lawyerInfo {
  display: flex;
  flex-direction: row;
  gap: 10px;
  padding: 40px 0;
  align-items: center;
}
.Lawyer-data h3 {
  color: #000;
  font-family: raleway;
  font-size: 30px;
  font-weight: 600;
  margin-bottom: 0px;
}
.Lawyer-data p {
  margin-bottom: 0px;
  font-family: raleway;
  font-size: 13px;
  color: #000;
}
.lawyerActions {
  display: flex;
  flex-direction: row;
  /* height: 100%; */
  gap: 30px;
  display: flex;
  flex-direction: row;
  position: absolute;
  top: 8%;
  right: 10%;
}
.lawyerActions button {
  background: #5d54f1;
  height: 100%;
  padding: 5px 25px 5px 25px;
  font-size: 15px;
  font-weight: 500;
  font-family: "Raleway";
  border-radius: 15px;
  box-shadow: 0 9px 14px rgba(0, 0, 0, 0.2), 0 13px 30px rgba(0, 0, 0, 0.2);
}
.lawyers-info {
  display: flex;
  gap: 10px;
  font-family: "Raleway";
  align-items: center;
  justify-content: flex-start;
}

.lawyerList {
  -webkit-overflow-scrolling: touch;
  overflow: -moz-scrollbars-none;
  -ms-overflow-style: none;
  scrollbar-width: none;
  overflow-y: scroll;
}

.LawyerList::-webkit-scrollbar {
  display: none;
}

.lawyerCard:hover {
  background-color: #e7e5fd;
  box-shadow: 0 9px 14px rgba(0, 0, 0, 0.2), 0 13px 30px rgba(0, 0, 0, 0.2);
  cursor: pointer;
}

.profileimagetext {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: raleway;
  font-weight: 600;
  color: white;
}
.profileimagetext h2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: raleway;
  font-weight: 600;
}
.below-content h3 {
  font-family: raleway;
  font-size: 20px;
}
.below-content {
  text-align: left;
  margin-top: 10px;
}
.caseInfo {
  color: white;
  font-family: ralewa;
}
.caseInfo p {
  color: white;
  margin-bottom: 0px;
  width: 100%;
  font-size: 12px;
}
.lawyer-case-contain p {
  margin-bottom: 0;
  font-size: 14px;
  margin: 10px 0px;
}
.lastUpdate p {
  font-family: raleway;
  font-size: 14px;
  color: white;
  margin-bottom: 0px;
  margin: 10px 0;
}
.sidebar-search {
  width: 21%;
  padding: 20px;
  border-right: 1px solid #ddd;
  background-color: white;
}

.searchOption {
  margin-bottom: 5px;
}

.searchOption h4 {
  margin-top: 10px;
}

.firstHalf {
  font-family: "Raleway", sans-serif;
  display: flex;
  flex-direction: column;
}

.horizontalLine,
.section-horizontalLine {
  border: 1px solid #ddd;
}

.mt-2 {
  margin-top: 20px;
}

input[type="number"] {
  /* margin: 5px 0; */
  /* border: 1px solid black; */
}

/* LawyerList */

.lawyerList {
  width: 60%;
  padding: 20px;
  background-color: white;
  /* height: 100vh; */
  overflow-x: hidden;
  overflow-y: scroll;
}

.lawyerCard {
  display: flex;
  justify-content: space-between;
  padding: 0px;
  margin-bottom: 20px;
  border: 1px solid #ddd;
  border-radius: 20px;
  position: relative;
  background-color: white;
}

.lawyerInfo {
  flex: 1;
}

.lawyerActions {
  display: flex;
  flex-direction: row;
}

.lawyer-img img {
  width: 100px;
  height: auto;
  border-radius: 50%;
}

.Lawyer-data {
  margin-left: 20px;
}

.lawyers-info p {
  margin: 5px 0;
}

/* for side lawyer profile */

.lawyerProfile {
  width: 20%;
  padding: 15px;
  border-left: 1px solid #ddd;
  background-color: #000;
  border-radius: 5px;
}

.profileimagetext {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.profileimagetext img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 10px;
}

.below-content {
  margin-top: 15px;
}

.caseInfo {
  margin-bottom: 6px;
}

.lawyer-case-contain {
  margin-bottom: 10px;
}

.lastUpdate {
  margin-top: 20px;
}
.closeButton {
  display: none;
}
.view-more {
  display: none;
}
.lawyerActions {
  display: flex;
  justify-content: space-between;
  padding: 10px;
}

.bio {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* Number of lines to show */
  -webkit-box-orient: vertical;
  white-space: normal;
}

.bio.expanded {
  -webkit-line-clamp: unset;
  max-height: none;
}

.view-more {
  cursor: pointer;
  color: #5d54f1;
  font-weight: bold;
  display: none; /* Hide on larger screens */
}
.mobile-two {
  display: flex;
  gap: 10px;
}
.mobile-second {
  display: flex;
  gap: 10px;
}
.view-more-button {
  display: none;
}

.bio-full {
  display: inline;
}
.apply-button {
  display: flex;
  align-items: center;
  margin-top: 50px;
  justify-content: center;
}
.filter-lawyer button {
  background-color: #5d54f1;
  padding: 5px 30px;
}
.mobile-lawyer-action {
  display: none;
}

@media (max-width: 768px) {
  .sidebar-search {
    transform: translateY(-100%);
    transition: transform 0.3s ease-in-out;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: white; /* Optional: to ensure the sidebar is visible */
    z-index: 1000; /* Ensure it appears above other elements */
  }

  .sidebar-search.visible {
    transform: translateY(0);
    padding: 50px 0px 0px 20px;
  }
  .apply-button {
    margin: 20px;
  }
  .mobile-lawyer-action {
    display: flex;
    gap: 10px;
    align-items: center;
  }
  .compare {
    background-color: #000;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 6px 13px;
    padding-top: 3px;
  }
  .lawyer-contact {
    background-color: #5d54f1;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 6px 13px;
    padding-top: 3px;
  }

  .closeButton {
    position: absolute;
    top: 10px;
    /* right: 20px; */
    background: #000;
    border: none;
    font-size: 20px;
    cursor: pointer;
  }

  .searchOption {
    margin-bottom: 5px;
  }

  .searchOption h4 {
    margin-top: 10px;
  }

  .firstHalf {
    font-family: "Raleway", sans-serif;
    display: flex;
    flex-direction: column;
  }

  .horizontalLine,
  .section-horizontalLine {
    border: 1px solid #000;
    height: 0px;
  }

  .mt-2 {
    margin-top: 20px;
  }

  input[type="number"] {
    margin: 5px 0;
    border: 1px solid black;
  }
  .lawyerList {
    width: 100%;
    padding: 5px;
  }
  .lawyerInfo {
    flex-direction: column;
    padding: 20px 10px 5px 0px;
  }
  .lawyerCard:hover {
    background-color: white;
    box-shadow: none;
  }

  .lawyer-img img {
    border: 1px solid black;
    position: absolute;
    left: 0;
    top: 33%;
    background: white;
  }
  .Lawyer-data {
    border: 1px solid black;
    margin-left: 0px;
    padding: 10px;
    border-radius: 15px;
    margin-left: 50px;
  }
  .Lawyer-data-content {
    padding-left: 50px;
  }
  .lawyerActions {
    display: none;
  }
  .lawyerProfile {
    display: none;
  }
  .lawyerCard {
    margin-bottom: 0px;
    border: none;
  }
  .bio {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .bio.expanded {
    white-space: normal;
  }

  .view-more {
    display: none;
  }
  .lawyers-info {
    flex-direction: column;
    align-items: flex-start;
    gap: 0px;
  }
  .mobile-two {
    display: flex;
    gap: 10px;
  }
  .mobile-second {
    display: flex;
    gap: 10px;
  }
  .view-more-button {
    background: none;
    font-family: raleway;
    color: #5d54f1;
    font-weight: 500;
    margin-left: -5px;
  }

  .view-more-button {
    display: inline;
  }

  .bio-full {
    display: none;
  }
}

.main-container {
  display: flex;
  justify-content: center;
}

.searchBar {
  display: flex;
  justify-content: center;
  padding: 20px;
  width: 85%;
}

.select {
  margin: 0px 10px;
  padding: 5px;
  height: 95%;
  align-items: center;
  justify-content: center;
  display: flex;
  border-radius: 10px;
  width: 15%;
  font-family: "Raleway", sans-serif;
}

.input {
  margin: 0 10px;
  padding: 10px;
  height: 40px;
  font-family: "Raleway", sans-serif;
}

.mobile-filter-button {
  display: flex;
  flex-direction: row;
}

.button,
.filterButton {
  margin: 0 10px;
  padding: 4px;
  width: 25%;
  background-color: black;
  border-radius: 15px;
  height: 90%;
  font-family: "Raleway", sans-serif;
  color: white;
  border: none;
  cursor: pointer;
}

@media (max-width: 768px) {
  .filterButton {
    display: block;
  }
  .closeButton {
    display: block;
  }
}
