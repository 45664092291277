/* supreme court */
.supreme-court-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 100px;
  /* height: 80vh; */
}
.supreme-court-container-content .form-container {
  /* background: #e7e5fd; */
  display: flex;
  align-items: center;
  justify-content: center;
  /* width: 60%; */
  box-shadow: 0 9px 14px rgba(0, 0, 0, 0.2), 0 13px 30px rgba(0, 0, 0, 0.2);
}

.supreme-court-container-content .form-container form {
  width: 100%;
}
.supreme-court-container-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

/* .supreme-court-container-content h1 {
  font-weight: 800;
  font-family: raleway;
} */
/* .supreme-court-container-content p {
  border: 2px solid black;
  padding: 10px;
  border-radius: 10px;
  font-weight: 600;
  font-size: 20px;
  margin-top: 10px;
} */

.supreme-court-buttons {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #656565;
  font-family: raleway;
  font-size: 15px;
  gap: 15px;
  margin-top: 10px;
}

.supreme-court-buttons button {
  /* background-color: #5d54f1; */
  padding: 7px;
  font-family: raleway;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  box-shadow: 0 9px 14px rgba(0, 0, 0, 0.2), 0 13px 30px rgba(0, 0, 0, 0.2);
  border: 1px solid black;
}

.supreme-court-buttons img {
  width: 23px;
  height: 23px;
  position: relative;
}

.supreme-court-buttons span {
  font-weight: 600;
  color: #656565;
  font-size: 20px;
}

.supreme-court-buttons-case-list {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: #656565;
  font-family: raleway;
  font-size: 15px;
  gap: 15px;
  margin-top: 10px;
  margin-bottom: 20px;
}
.supreme-court-buttons-case-list-mobile {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #656565;
  font-family: raleway;
  font-size: 15px;
  gap: 15px;
  margin-top: 10px;
}


.supreme-court-buttons-case-list-mobile  button {
  /* background-color: #5d54f1; */
  padding: 7px;
  font-family: raleway;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  border: 1px solid black;
  box-shadow: 0 9px 14px rgba(0, 0, 0, 0.2), 0 13px 30px rgba(0, 0, 0, 0.2);
}

.supreme-court-buttons-case-list button {
  /* background-color: #5d54f1; */
  padding: 7px;
  font-family: raleway;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  border: 1px solid black;
  box-shadow: 0 9px 14px rgba(0, 0, 0, 0.2), 0 13px 30px rgba(0, 0, 0, 0.2);
}

.supreme-court-buttons-case-list img {
  width: 23px;
  height: 23px;
  position: relative;
}

.supreme-court-buttons-case-list span {
  font-weight: 600;
  color: #656565;
  font-size: 20px;
}

.supreme_court_bottom_illustration {
  position: absolute;
  z-index: -1;
  left: 0;
  bottom:0;
}

.supreme_court_bottom_illustration img {
  width: 80%;
}

.supreme_court_bottom_right_illustration {
  position: absolute;
  right: 0;
  bottom: 0;
}

.supreme_court_bottom_right_illustration img {
  width: 80%;
}

.form-group select {
  font-family: raleway;
  font-weight: 500;
  /* color: #333333; */
}

/* .form-group input::placeholder {
  font-family: raleway;
  font-weight: 500;
  color: #333333;
} */

@media (max-width: 768px) {
  .supreme_court_bottom_right_illustration {
    display: none;
  }
  .supreme_court_bottom_illustration {
    display: none;
  }

  .supreme-court-container-content .form-container {
    width: 100%;
  }
  .supreme-court-container-content p {
    font-size: 16px;
    text-align: center;
  }
}
