.app {
  text-align: center;
  margin-top: 72px;
}

.header {
  background-color: #f5f5f5;
  padding: 10px 0px 0px 0px;
  margin-bottom: 20px;
}

.court-dashboard-button {
  display: flex;
  align-items: center;
  justify-content: center;
  color: black;
  font-weight: 800;
  font-size: 19px;
  font-family: "Raleway";
  border: 10px solid #c2beff;
  border-radius: 15px;
  padding: 0;
  background: none;
  font: inherit;
  line-height: 1;
  position: relative;
  overflow: hidden;
  transition: color 0.25s;
}
.court-dashboard-button span {
  display: flex;
  flex-direction: row;
  gap: 5px;
  padding: 10px;
  display: flex;
  align-items: center;
  font-weight: 700;
}

/* Basic button styles */

/* button:hover,
button:focus { 
  color: #fff;
} */

/* Slide button color and effect */
.slide1 {
  --color: #8fc866;
  --hover: #668fc8;
  color: black;
}

@keyframes slide {
  2% {
    box-shadow: 0 0 0 0 var(--hover);
  }
}

.slide1 {
  --color: #8fc866;
  --hover: #668fc8;
  border-color: var(--color);
}

/* Pseudo-element for sliding effect */
.slide1::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--hover);
  z-index: -1;
  transform: translateX(-100%);
  transition: transform 0.5s ease;
}

.slide1:hover::before,
.slide1:focus::before {
  transform: translateX(0);
}

.content {
  display: flex;
  /* justify-content: space-around; */
  gap: 0px;
  padding: 10px 0px 0px 60px;
}

.case-details,
.important-dates {
  width: 50%;
}

.case-details {
  border-radius: 8px;
  overflow: hidden;
  /* width: 90%;  */
}

.case-details {
  overflow: hidden;
  margin: 14px 0px auto;
  width: 45%;
  border-left: 1px solid #000;
  border-right: 1px solid #000;
  border-top: 25px solid black;
  border-bottom: 1px solid black;
}
.case-details th:first-child,
.case-details td:first-child {
  border-right: 1px solid #000;
}
.case-details th:second-child,
.case-details td:second-child {
  border-right: 1px solid #000;
  color: white;
  font-family: raleway;
  font-weight: bold;
  font-size: 15px;
}
.case-details th::before {
  content: "→";
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  transition: opacity 0.3s;
}
.case-details th:hover th::before {
  opacity: 1;
}
.case-details th:first-child:hover {
  color: white;
}
.case-details td:first-child:hover,
.case-details th:first-child:hover {
  color: white;
}

.case-details table {
  width: 100%;
  border-collapse: collapse;
}

.case-details th,
.case-details td {
  text-align: left;
  padding: 15px 20px;
  color: #4c4c4c;
  font-family: raleway;
  font-size: 15px;
  font-weight: 600;
}

.case-details td {
  /* background-color: #f9f9f9; */
}
.case-details th {
  font-weight: bold;
  /* color: #5d54f1; */
}
.case-headings {
  padding: 15px 0px 0px 55px;
  text-align: left;
}
.case-headings p {
  margin-bottom: 0px;
}
.main-head {
  font-size: 35px;
  font-family: Mulish;
  /* color: #000; */
  font-weight: 900;
}
.case-main-details {
  /* margin-top: 30px; */
  font-family: raleway;
  font-size: 30px;
  font-weight: bold;
}
.case-main-details p {
  color: black;
  font-size: 25px;
}

.highlight {
  background-color: #ffcc00;
}
.important-dates-container {
  display: flex;
  background: #ffffff;
  background-color: rgba(93, 84, 241, 0.15);
  padding: 20px 20px 0px 20px;
  margin-right: 30px;
  align-items: flex-start;
  display: flex;
  justify-content: space-around;
  position: relative;
  gap: 30px;
}
.corner-next-hearing p {
  font-size: 14px;
  margin-bottom: auto;
  right: 4%;
  position: absolute;
  font-weight: 700;
  bottom: 1%;
}
.calendar {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: 10px;
  font-size: 16px;
}

.day {
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.highlight {
  background: #000;
  color: white;
  font-weight: bold;
  cursor: pointer;
}

.date-details {
  margin-left: 20px;
  border-left: 4px solid orange;
  padding-left: 20px;
}

.date-info .date-title {
  font-weight: bold;
  margin-bottom: 5px;
}

.buttons {
  margin-top: 20px;
}

.buttons button {
  margin-right: 10px;
  padding: 10px 20px;
  background-color: #f0f0f0;
  border: none;
  cursor: pointer;
}

.buttons button:hover {
  background-color: #e0e0e0;
}
.case-details-buttons {
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 25px;
  margin-top: 50px;
}
.right-side-dates {
  padding: 0px 40px 0px 40px;
  border-radius: 8px;
  align-items: flex-start;
  width: 60%;
  margin: 14px 0px auto;
}
.content img {
  /* width: 2%; */
  height: auto;
  display: block;
  margin: 0;
  z-index: -1;
}
.top-image {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 200px; /* Adjust the height as needed */
  width: 100%;
  margin: 0; /* Ensure no margin */
  padding: 0; /* Ensure no padding */
}

.order-summary {
  background-color: #f9f9f9;
  margin: 20px 60px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.order-summary-header {
  background-color: #000;
  color: #fff;
  padding: 10px;
  border-radius: 8px 8px 0 0;
  text-align: left;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.order-summary-header h2 {
  margin: 0;
}

.order-summary-header span {
  font-size: 24px;
}

.order-summary-content {
  display: flex;
  justify-content: space-between;
  padding: 20px;
}

.order-summary-column {
  flex: 1;
  margin: 10px;
}

.order-summary-column p {
  margin-bottom: 10px;
  color: #000000;
  font-family: raleway;
  font-size: 15px;
  text-align: left;
}
.order-summary {
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  cursor: pointer;
}

.order-summary-header {
  background-color: #000;
  color: #fff;
  padding: 10px;
  border-radius: 8px 8px 0 0;
  text-align: left;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.order-summary-header h2 {
  margin: 0;
}

.order-summary-header span {
  font-size: 24px;
}

.order-summary-content {
  display: flex;
  justify-content: space-between;
  padding: 20px;
  background: #e7e5fd;
}

.order-summary-column {
  flex: 1;
  margin: 10px;
}

.order-summary-column p {
  margin-bottom: 10px;
}
.chatbot-button {
  font-family: "Raleway";
  /* background-color: black; */
  /* font-size: 20px;
  border-radius: 18px; */
  box-shadow: 0 9px 14px rgba(0, 0, 0, 0.2), 0 13px 30px rgba(0, 0, 0, 0.2);
  margin-top: 30px;
  /* padding: 3px 30px 0px 30px; */
}
.don-button {
  background-color: #5d54f1;
  /* width: 170px; */
  height: 40px;
  border-radius: 10px;
  box-shadow: 0 9px 14px rgba(0, 0, 0, 0.2), 0 13px 30px rgba(0, 0, 0, 0.2);
  padding: 0px 20px;
}
.case-details th:hover {
  background-color: #5d54f1;
  cursor: pointer;
  opacity: 1;
}
.case-details tr:hover td {
  color: #000;
}
.table-with-vector {
  position: relative; /* Establishes a positioning context */
}
.table-vector {
  position: absolute;
  top: -20vh;
  right: 0%;
  width: 65%;
  /* width: 50px; Fixed width, adjust or use percentage as needed */
}
.case-dates {
  text-align: left;
  padding: 20px;
  height: 30vh;
  overflow: auto;
}

.right-side-dates h5 {
  font-family: mulish;
  font-weight: 800;
  font-size: 25px;
}

.right-side-dates span {
  text-align: left;
  display: flex;
  margin: 10px 0px;
  color: #5d54f1;
  font-family: "Raleway";
  font-weight: 600;
  background: #f3f3f3;
  width: fit-content;
  padding: 6px 6px;
  border-radius: 10px;
}

.mobile-instruction {
  display: contents;
  display: flex;
  align-items: center;
  /* justify-content: center; */
  gap: 6px;
  margin-top: 20px;
}

/* Timeline.css */

.timeline {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  margin: 20px 0;
  padding: 10px 0;
  list-style-type: none;
}

.timeline::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 4px;
  background: #d4d4d4;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  z-index: -1;
}

.timeline-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.timeline-item::before {
  content: "";
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #d4d4d4;
  border: 4px solid #fff;
  box-shadow: 0 0 0 4px #d4d4d4;
  margin-bottom: 10px;
}

.timeline-item.active::before {
  background: #4caf50;
  box-shadow: 0 0 0 4px #4caf50;
}

.timeline-label {
  margin-top: 5px;
  font-weight: bold;
}

.mobile-instruction p {
  font-weight: 700;
  color: #5d54f1;
  margin-bottom: 0px;
}
.order-summary-content p {
  text-align: left;
  /* font-family: raleway; */
  margin-bottom: 0px;
  padding: 10px 0px;
}

.casedetails-app-container {
  height: 100vh;
  display: flex;
  /* gap: 20px; */
}

.casedetails-content-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 50%;
}

.casedetails-upper-section {
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
.casedetails-upper-section img {
  position: absolute;
  width: 60%;
  bottom: 0;
}

.casedetails-lower-section1 {
  /* background-color: #5d54f1; */
  /* height: 35%; */
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0px 0px 0px 40px;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  position: absolute;
  bottom: 0;
  width: 50%;
}
.casedetails-lower-section1 img {
  width: 65%;
}
.casedetails-lower-section {
  background-color: #5d54f1;
  display: flex;
  padding: 50px;
  flex-direction: column;
  align-items: center;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  bottom: 0;
  width: 100%;
}
.casedetails-lower-section h3 {
  font-family: raleway;
  font-weight: 700;
  margin-top: 25px;
  font-size: 40px;
}
.casedetails-lower-section p {
  font-family: raleway;
  font-size: 18px;
  color: white;
  margin-bottom: 0px;
  font-weight: 400;
}
.casedetails-lower-section button {
  background-color: #f9f9f9;
  font-family: raleway;
  color: black;
  font-size: 18px;
  font-weight: 600;
  padding: 10px 20px;
  border: none;
  border-radius: 10px;
  margin-top: 20px;
  cursor: pointer;
}
.casedetails-footer p {
  margin-top: 20px;
  color: white;
}
.casedetails-right-section {
  width: 50%;
  padding: 30px 50px 0px 100px;
}
.footer-right {
  padding: 70px;
  background: #e7e5fd;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
.casedetails-right-section {
  position: absolute;
  right: 0;
  bottom: 0;
}

.Ai-model {
  display: flex;
  align-items: center;
  justify-content: center;
}
.Ai-model p {
  /* margin-top: 20px; */
  font-weight: 700;
  /* color: #5d54f1; */
}

.footer-right h6 {
  font-family: raleway;
  font-size: 22px;
  margin: 0px;
  font-weight: 600;
}
.footer-right ul {
  font-size: 20px;
}
.footer-right p {
  font-size: 20px;
  font-family: raleway;
}
.New-Chatbot-Butoon {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 35px;
}
.New-Chatbot-Butoon p {
  font-size: 19px;
  width: 100%;
  margin-top: 16px;
  display: flex;
  gap: 15px;
  align-items: center;
  justify-content: center;
  /* color: #5d54f1; */
}
@media (max-width: 768px) {
  .content {
    flex-direction: column;
    padding: 0px 20px 20px 20px;
  }
  .New-Chatbot-Butoon p {
    font-size: 18px;
  }
  .case-main-details p {
    font-size: 18px;
  }
  .case-details {
    width: 100%;
  }
  .case-details th {
    width: 50%;
  }
  .case-headings {
    padding: 15px 20px 0px 20px;
  }
  .order-summary {
    margin: 1rem;
  }
  .order-summary-content {
    text-align: left;
    background: #e7e5fd;
  }
  .case-details th,
  .case-details td {
    padding: 10px 7px;
  }
  .content img {
    width: 150%;
  }
  .table-vector {
    top: -12vh;
    display: none;
  }
  .main-head {
    font-size: 20px;
  }
  .view {
    align-items: center;
  }
  .right-side-dates {
    padding: 20px;
    width: 100%;
  }
  .important-dates-container {
    flex-direction: column;
    gap: 0px;
  }
  .case-dates {
    height: 18vh;
    padding-bottom: 0px;
  }
  .corner-next-hearing p {
    position: relative;
    text-align: left;
    left: 20px;
    margin-bottom: 15px;
  }
  .important-dates-container {
    padding: 0px;
    margin-right: 0px;
  }
  .case-details-buttons {
    flex-direction: column;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: 10px;
  }
  .mobile-instruction {
    display: flex;
    flex-direction: row;
    margin-top: 20px;
    align-items: flex-start;
  }

  .mobile-instruction p {
    margin-bottom: 0px;
    color: #5d54f1;
    text-align: left;
  }
  .chatbot-button {
    width: 100%;
    padding: 0px;

    font-size: 16px;
    font-weight: 500;
    font-family: "Raleway";
  }
  .don-button {
    width: 80%;
    font-size: 16px;
    font-weight: 500;
    font-family: "Raleway";
  }
  .mobile-instruction img {
    height: 20px;
    width: 20px;
  }
  .view img {
    height: 20px;
  }
  .calendar {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 5px;
    font-size: 14px;
    padding: 20px;
  }
  .important-dates-container {
    align-items: normal;
  }
  .casedetails-app-container {
    flex-direction: column;
  }
  .casedetails-lower-section1 {
    position: relative;
    width: 100%;
    margin: 100px 0px 0px 0px;
  }
  .casedetails-lower-section {
    padding: 30px;
  }
  .casedetails-content-container {
    width: 100%;
    /* margin: 0px; */
  }
  .footer-right {
    padding: 20px;
  }
  .casedetails-right-section {
    position: relative;
    width: 100%;
    padding: 0px;
  }
}
