/* Hide Navbar on mobile screens */
@media screen and (max-width: 768px) {
  .firstNavbar {
    display: none;
  }
}

/* Ensure Navbar is displayed on larger screens */
@media screen and (min-width: 769px) {
  .firstNavbar {
    display: block;
  }
}
