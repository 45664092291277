/* NotificationBar.css */
.notification-bar {
  /* background: linear-gradient(to bottom, #5d54f1 0%, #36308b 100%); */
  /* color: white; */
  text-align: center;
  padding: 10px 0;
  font-size: 16px;
  position: fixed;
  /* top: 0; */
  width: 100%;
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 20px;
  margin-top: 50px;
}

.notification-close-button {
  display: flex;
  background: none;
  border: none;
  /* color: white; */
  font-size: 30px;
  position: absolute;
  right: 1%;
  cursor: pointer;
  top: 0;
  align-items: center;
  justify-content: center;
  bottom: 0px;
}

.notification-para p {
  margin-bottom: 0;
  font-size: 20px;
  /* color: white; */
  font-weight: 500;
}
/* NotificationBar.css */

.notification-para {
  margin: 0;
}

.feedback-link {
  cursor: pointer;
  text-decoration: underline;
  /* color: #e7e5fd; */
}

.notification-close-button {
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
}

.popup {
  position: fixed;
  /* top: 5%; */
  left: 0;
  width: 100%;
  height: 100%;
  /* background: rgba(255, 255, 255, 0.8); White effect background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.popup-content {
  /* background: white; */
  /* padding: 20px; */
  /* border-radius: 8px; */
  width: 450px;
  max-width: 100%;
  position: relative;
}

.popup-close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
}

.popup-content h2 {
  margin-top: 0;
}

.popup-content form {
  display: flex;
  flex-direction: row;
}

.popup-beside-content {
  box-shadow: 0 9px 14px rgba(0, 0, 0, 0.2), 0 13px 30px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
}

.under-input-section {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.under-input-section h5 {
  font-family: raleway;
  margin: 35px 0px 10px 0px;
  font-size: 16px;
  font-weight: 600;
}
.popup-content textarea {
  margin-bottom: 10px;
  padding: 10px;
  border: 3px solid #7d7d7d;
  border-radius: 8px;
  width: 100%;
  resize: vertical;
}
.pop-up-submit {
  display: flex;
  align-items: center;
  justify-content: center;
}

.pop-up-submit button {
  padding: 5px 15px;
  /* background-color: #000; */
  /* color: white; */
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-weight: 600;
  font-family: raleway;
}
.rating {
  text-align: center;
  font-family: raleway;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
}
.rating h6 {
  margin-bottom: 10px;
  margin-top: 30px;
  font-family: "Raleway";
  font-weight: 600;
  font-size: 16px;
}
.inputs {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
  flex-direction: column;
}
.inputs select {
  margin: 0px 10px;
  padding: 8px;
  align-items: center;
  justify-content: center;
  display: flex;
  border-radius: 10px;
  width: 70%;
  font-family: "Raleway", sans-serif;
  border: 2px solid #666666;
  background: #f3f3f3;
}
.popup-close-button {
  right: 2%;
  font-size: 30px;
  position: absolute;
  top: 0%;
  color: white;
}
.notificationBar-content {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 12px;
  /* background-color: #5d54f1; */
  /* border-top-left-radius: 10px; */
  /* border-top-right-radius: 10px; */
}
.under-side-content {
  display: flex;
  gap: 20px;
  align-items: center;
}
.under-side-content img {
  height: 30%;
}
.notificationBar-content h2 {
  font-family: raleway;
  font-weight: 500;
  /* color: white; */
  font-size: 25px;
}
.notificationBar-content p {
  margin-bottom: 0;
  font-size: 13px;
  /* color: white; */
}
.inputs p {
  margin-bottom: 5px;
  font-weight: 600;
  font-size: 16px;
}
.offside-content {
  padding: 15px;
}

.rating input[type="radio"] {
  display: none;
}
.rating label {
  font-size: 17px;
  padding: 7px;
  /* border: 2px solid #5d54f1; */
  border-radius: 50%;
  margin: 0 5px;
  cursor: pointer;
  font-family: mulish;
  /* color: #5d54f1; */
  display: inline-block;
  height: 30px;
  align-items: center;
  justify-content: center;
  display: flex;
}
.rating input[type="radio"]:checked + label {
  /* background-color: #5d54f1; */
  background-color: black;
  color: white;
}

.rating-text {
  margin-top: 10px;
}
.rating-text span {
  font-size: 12px;
}
.rating-text span:first-child {
  float: left;
}
.rating-text span:last-child {
  float: right;
}

@media (max-width: 768px) {
  .notification-para p {
    font-size: 15px;
  }
  .notification-close-button {
    font-size: 20px;
    position: absolute;
    align-items: baseline;
    display: flex;
    justify-content: center;
    top: 30%;
  }
  .notification-para {
    padding: 4px 45px;
  }
  .popup-content {
    width: 350px;
  }
  .notificationBar-content h2 {
    font-size: 20px;
  }
  .rating label {
    margin: 2px;
  }
  .popup-close-button {
    right: 0%;
    font-size: 30px;
    position: absolute;
    top: -2%;
    color: white;
  }
}
