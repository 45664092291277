.all-high-court-cards {
  display: flex;
  align-items: center;
  gap: 3rem;
  width: 100%;
  justify-content: center;
  width: 100%;
  margin-top: 20px;
}
.main-high-court-container {
  width: 100%;
}
.all-dropdown {
  width: 100%;
  /* background: #e7e5fd; */
  z-index: 1;
  /* border-radius: 10px; */
  display: flex;
  position: relative;
  z-index: 1;
}
.all-dropdown h6 {
  color: black;
  font-weight: 600;
  font-size: 30px;
  width: 50%;
  display: flex;
  align-items:center;
}
.laptop-submit1 {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #5d54f1;
  color: black;
  width: 50%;
  border-radius: 12px;
  height: 35px;
  box-shadow: 0 9px 14px rgba(0, 0, 0, 0.2), 0 13px 30px rgba(0, 0, 0, 0.2);
  cursor: pointer;
}

.laptop-submit1 button{
  border:none;
  color:white;
  background-color:transparent;
  font-family: raleway;
  font-weight: 500;
  width: 100%;
}
.high_court_section{
  display: flex;
  flex-direction: row;
  gap: 50px;
  align-items:center;
  justify-content: center;
}
.all-high-court-form{
  width: 100%;
  padding: 15px;
}
.all-high-court-form form{
  display: flex;
}
.all-high-court-form .form-group{
  margin-bottom: 0px;
  width: 100%;
  padding: 10px;
}
.submit-laptop1{
  display: flex;
  align-items:center;
  justify-content: center;
  width: 100%;
}

@media (max-width: 768px) {
  .main-high-court-container {
    flex-direction: column;
    gap: 20px;
    width: 95%;
  }
  .all-dropdown {
    width: 100%;
    flex-direction: column;
    padding: 25px;
  }
  .high_court_section{
    flex-direction: column;
  }
  .all-dropdown h6{
    display: block;
    width: 100%;
  }
  .all-high-court-form form{
    flex-direction: column;
  }
}
