.lawyer-profile-container h2 {
  margin: 1rem 0rem 2rem 0rem;
  font-size: 45px;
  font-family: mulish;
  font-weight: 900;
}
.lawyer-profile-left-section {
  display: flex;
  background-color: #e5e4fd;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 5rem;
}
.lawyer-profile-sidebar {
  border-radius: 15px;
}
.laptopUserIcon {
  max-width: 500px;
  padding: 0px 0px 50px 0px;
  height: 88%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #000;
  border-radius: 15px;
}
.horizontal-line {
  border: none;
  border-top: 1px solid black;
  margin: 20px 0;
}
.lawyer-contact-details h6 {
  text-align: left;
  color: #5d54f1;
  font-weight: 600;
  font-size: 22px;
  margin-left: 0px;
}
.sixth-row {
  width: 100%;
}
.tab-content .sixth-row input[type="text"] {
  width: 100%;
  height: 50px;
}

.lawyer-profile-container {
  margin-top: 80px;
}

.lawyer-profile-landing-top-image {
  width: 20vw;
  height: auto;
  position: absolute;
  bottom: 0;
  z-index: 0;
  right: 5%;
}
.lawyer-profile-left-section-top {
  position: relative;
}

.laptop-tabs {
  display: flex;
  /* justify-content: space-around; */
  margin-bottom: 20px;
  background-color: #5d54f1;
  font-family: raleway;
  font-size: 16px;
}

.second-row {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
}

.tab-button {
  padding: 10px 20px;
  border: none;
  background: #ddd;
  cursor: pointer;
  border-radius: 5px;
  transition: background 0.3s ease;
  color: #000;
}

.tab-button.active {
  background: #e7e5fd;
  border-radius: 0px;
  border: none;
}

.tab-content form {
  display: grid;
  gap: 10px;
}

.tab-content .first-row input[type="text"] {
  padding: 10px;
  border: none;
  border-radius: 10px;
  font-size: 16px;
  height: 80px;
  width: 100%;
  background: #ffffff;
}

.third-row .form-group {
  width: 33%;
}
.lawyer-profile-progress-bar {
  width: 50%;
  background-color: #f0f0f0;
  border-radius: 4px;
  margin-bottom: 10px;
}
.lawyer-progress-bar-section {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  gap: 20px;
  justify-content: center;
}

.laywerProfileProgressBar {
  margin: 10px 30px 15px 30px;
  display: flex !important;
  flex-direction: row !important;
  align-items: center;
  width: 100%;
  gap: 20px;
  justify-content: center;
}
.laywerProfileProgressBar {
  display: flex;
  flex-direction: column; /* Adjust as needed, can be row or column */
  align-items: center; /* Adjust as needed, e.g., align-items: flex-start; */
  width: 100%; /* Ensure it takes the full width */
}

.laywerProfileProgressBar .progress {
  width: 100%; /* Ensure progress bar takes the full width of its container */
}

.laywerProfileProgressBar p {
  margin-top: 10px; 
  width: 15%;
}

.progress {
  height: 23px;
  color: white;
  text-align: center;
  line-height: 30px;
  border-radius: 4px;
  transition: width 0.3s ease;
  display: flex;
  align-items: center;
  font-size: 11px;
  font-family: mulish;
}
.bg {
  background-color: #a3a3a3;
  width: 50%;
  height: 30px;
  border-radius: 20px;
}
.progress-percent {
  font-weight: 700;
  font-size: 20px;
  font-family: raleway;
}

.left {
  float: left;
}

.right {
  float: right;
}

.lawyer {
  background: -webkit-linear-gradient(
    135deg,
    #6fdb6f 25%,
    #52c452 25%,
    #52c452 50%,
    #6fdb6f 50%,
    #6fdb6f 75%,
    #52c452 75%
  );

  background: -moz-linear-gradient(
    135deg,
    #6fdb6f 25%,
    #52c452 25%,
    #52c452 50%,
    #6fdb6f 50%,
    #6fdb6f 75%,
    #52c452 75%
  );

  background: -o-linear-gradient(
    135deg,
    #6fdb6f 25%,
    #52c452 25%,
    #52c452 50%,
    #6fdb6f 50%,
    #6fdb6f 75%,
    #52c452 75%
  );

  background: linear-gradient(
    135deg,
    #6fdb6f 25%,
    #52c452 25%,
    #52c452 50%,
    #6fdb6f 50%,
    #6fdb6f 75%,
    #52c452 75%
  );

  /* width: 80%; */
  height: 30px;
  line-height: 30px;
  color: #fff;
  background-size: 50px 50px;
  -webkit-animation: bar-animation 3s linear infinite;
  border-radius: 20px;
  padding-right: 30px;
}

@-webkit-keyframes bar-animation {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 50px 50px;
  }
}

/* .first-row {
  width: 100%;
} */

.fourth-row {
  width: 100%;
}
.fifth-row {
  width: 100%;
}
.sixth-row {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.edit-button {
  cursor: pointer;
  font-family: raleway;
  font-style: normal;
}

.edit {
  cursor: pointer;
}

.editButton {
  height: 20px;
  width: 20px;
}
.saveButton {
  display: flex;
  align-items: center;
  justify-content: center;
}
.submitButton {
  border-radius: 12px;
  background-color: #5d54f1;
  width: 15%;
  height: 50px;
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
}

.tab-content form label {
  margin: 10px;
}

.right-side-container {
  padding: 30px;
}

.lawyer-right-section {
  background-color: #f3f3f3;
  padding: 10px 40px 20px 40px;
  width: 100%;
  border-radius: 10px;
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.2);
}

.lawyer-right-section h2 {
  font-weight: 900;
  color: #5d54f1;
  text-align: center;
  font-family: "Mulish";
}

.lawyer-right-section form {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 45px;
}

.lawyer-right-section.form-group {
  width: 48%;
  margin-bottom: 20px;
  position: relative;
}

.lawyer-right-section.form-group label {
  display: block;
  margin-bottom: 5px;
  font-family: "Raleway";
  font-size: 17px;
  font-weight: 600;
}

.lawyer-right-section .form-group input,
.lawyer-right-section .form-group select,
.lawyer-right-section .form-group textarea {
  width: 100%;
  padding: 8px;
  border: 0px solid #ccc;
  border-radius: 5px;
  height: 40px;
  background-color: #ffffff;
  border-radius: 15px;
  font-family: "Raleway";
  /* opacity: 0.6; */
  color: black;
  font-weight: 500;
  height: 55px;
}
.lawyer-right-section .form-group label {
  display: block;
  margin-bottom: 5px;
  font-family: "Raleway";
  font-size: 14px;
  font-weight: 700;
}
.lawyer-input-icon {
  position: absolute;
  right: 30px;
  top: 50%;
  pointer-events: none; /* This ensures that the icon does not block clicks on the input */
  font-size: 16px;
  color: #999;
}

.mobileUserIcon {
  display: none;
}

.laptop-tabs {
  display: flex;
  overflow-x: auto;
  white-space: nowrap;
  border-radius: 10px;
  margin: 10px;
  justify-content: space-between;
}

.tab-button {
  padding: 10px 20px;
  cursor: pointer;
  border: none;
  background-color: #f0f0f0;
  /* margin-right: 10px; */
  border-radius: 5px;
  transition: background-color 0.3s;
  background-color: #5d54f1;
  color: white;
}

.tab-button.active {
  background: #e7e5fd;
  border-radius: 0px;
  border: none;
  color: black;
}
.add-new-fields {
  display: flex;
  align-items: center;
  justify-content: center;
}
.add-new-fields p {
  font-family: "Raleway";
  color: #5d54f1;
  font-family: raleway;
  font-weight: 500;
  background: transparent;
  border: 1px solid #5d54f1;
  padding: 8px;
  margin: 18px;
  cursor: pointer;
  font-weight: 800;
}
.tab-content {
  margin-top: 20px;
}
.mobiletabs {
  display: none;
}
.lawyer-profile-delete-button {
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  color: #5d54f1;
  font-weight: 800;
}
.lawyer-profile-delete-button button {
  background: transparent;
  color: #5d54f1;
  font-weight: 800;
  border: 1px solid #5d54f1;
  padding: 5px 40px;
  margin: 40px;
}

@media (max-width: 768px) {
  .mobileUserIcon {
    display: block;
    background-color: #202020;
  }
  .lawyer-profile-top-container {
    display: none;
  }

  .laptopUserIcon {
    display: none;
  }
  .lawyer-profile-left-section {
    flex-direction: row;
    gap: 1rem;
  }
  .lawyer-contact-details h6 {
    display: none;
  }
  .horizontal-line {
    display: none;
  }
  .lawyer-profile-landing-top-image {
    display: none;
  }
  .lawyerProfileIContactImages {
    height: 20px;
    width: 20px;
  }
  .lawyer-profile-left-section-top {
    padding: 10px;
    color: white;
  }
  .lawyer-profile-left-section {
    background-color: #000;
    padding: 25px 25px 20px 30px;
    border-radius: 15px;
  }
  .lawyer-profile-container h2 {
    margin: 1rem 0rem 1rem 0rem;
    font-size: 30px;
  }
  .mobiletabs {
    scrollbar-width: none;
  }

  .mobiletabs::-webkit-scrollbar {
    display: none;
  }

  .tab-button {
    flex: 0 0 auto;
    /* margin-right: 10px; */
  }
  .lawyer-right-section {
    padding: 5px 0px 0px 5px;
    background: #e7e5fd;
  }
  .submitButton {
    width: 65%;
    height: 40px;
    margin-bottom: 10px;
    font-size: 20px;
    box-shadow: 0 9px 14px rgba(0, 0, 0, 0.2), 0 13px 30px rgba(0, 0, 0, 0.2);
  }
  .tab-button {
    padding: 0px 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    background-color: transparent;
    color: #7d7d7d;
  }
  .lawyer-right-section .form-group input::placeholder {
    color: black;
    font-family: raleway;
  }
  .laptop-tabs {
    display: none;
  }
  .third-row .form-group {
    width: 100%;
  }
  .mobiletabs {
    display: block;
    display: flex;
    /* justify-content: space-around; */
    margin-bottom: 20px;
    font-family: raleway;
    font-size: 16px;
    overflow: auto;
  }
  .tab-button.active {
    margin-left: 0px;
    margin-right: 0px;
    padding: 0 15px;
    border-radius: 10px;
    background: #5d54f1;
    color: white;

    /* border-top-right-radius: 10px;
    border-bottom-right-radius: 10px; */
  }
  .second-row {
    flex-direction: column;
  }
  .lawyer-progress-bar-section {
    gap: 15px;
  }
  .progress-percent {
    font-size: 14px;
  }
  .laywerProfileProgressBar{
    /* flex-direction: column !important; */
    width: 75%;
  }
}
