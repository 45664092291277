@import "~slick-carousel/slick/slick.css"; 
@import "~slick-carousel/slick/slick-theme.css";

.testimonial-container {
    background-color: #f4f4f4;
    padding: 40px 0px 100px 0px;
    margin: auto;
}

.testimonial-header {
    text-align: center;
    font-weight: 900;
    font-size: 45px;
    color: black;
    font-family: Mulish;
    margin: 2rem;
}

.slick-slide img {
    display: block;
    /* border-radius: 100px;s */
}

.slick-dots ul {
    display: none;
}

.slide {
    text-align: center;
    padding: 20px;
    margin-top: 113px;
    position: relative;
    text-align: center;
    transition: .5s;
    width: 40%;
}

.testimonial-image-container img {
    width: 150px;
    height: 150px;
    border-radius: 50%; 
    border: 1px solid black;
    margin-bottom: 20px;
}

.testimonial-image-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.testimonial-details {
    background: linear-gradient(to bottom, white 72%, #000 50%);
    color: white;
    padding: 20px;
    border-radius: 40px;
    width: 45%;
    margin: 0 auto;
    box-sizing: border-box;
}

.arrow {
    font-size: 24px;
    color: #333;
    cursor: pointer;
}
.auth-name{
    margin-bottom: 45px;
}

.arrow-right {
    position: absolute;
    right: 5%;
    top: 50%;
}

.arrow-left {
    position: absolute;
    left: 5%;
    top: 50%;
}

.testimonial-image-box {
    display: flex;
    justify-content: center;
    border-radius: 100px;
    margin-top: -120px;
}
/* hello */

.arrow{

  z-index: 100;
  cursor: pointer;
  color:black;
  font-weight: bold;
}

.auth-name p {
  font-family: Raleway;
  font-size: 21.6px;
  font-weight: bold;
  color:#000
}
.testimonial-content p {
  width: 100%;
  margin-top: 15px;
  font-family: Raleway;
  color:#000;
}
.blur {
    filter: blur(5px);
    opacity: 0.5;
    transition: filter 0.5s ease, opacity 0.5s ease;
}

.no-blur {
    filter: none;
    opacity: 1;
    transition: filter 0.5s ease, opacity 0.5s ease;
}

/* .inactive-slide {
    filter: blur(5px);
    opacity: 0.5;
    left: -40%;
    top: -20px;
    transition: filter 0.5s ease, opacity 0.5s ease;
}
.inactive-slide1 {
    filter: blur(5px);
    opacity: 0.5;
    left: 40%;
    top: -20px;
    transition: filter ease, opacity 0.5s ease;
} */

@media (max-width:768px) {
    .testimonial-details{
        width: 100%;
        background: linear-gradient(to bottom, white 85%, #000 50%);
    }
    .auth-name{
        display: none;
    }
    .testimonial-header{
        font-size: 38px;
    }
    .testimonial-details {
        padding: 10px 0px 10px 0px;
    }
    .stars{
        display: flex;
        align-items:center;
        justify-content: center;
    }
    .testimonial-content p{
        padding: 10px;
        font-size: 15px;
    }
}