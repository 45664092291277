.profile-container {
  display: flex;
  gap: 20px;
  padding: 20px;
  font-family: Arial, sans-serif;
  margin-top: 80px;
}

.left-section {
  background-color: #000;
  color: #fff;
  width: 22%;
  text-align: center;
  border-radius: 15px;
}

.left-section h2 {
  margin: 10px 0;
}

.contact-details p {
  margin: 10px 0;
}

.right-section {
  /* background-color: #e7e5fd; */
  padding: 10px 40px 20px 40px;

  width: 60%;
  border-radius: 10px;
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.2);
}

.right-section h2 {
  font-weight: 900;
  /* color: #5d54f1; */
  text-align: center;
  font-family: "Mulish";
}

.right-section form {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 45px;
}

.right-section .form-group {
  width: 48%;
  margin-bottom: 20px;
  position: relative;
}

.right-section .form-group label {
  display: block;
  margin-bottom: 5px;
  font-family: "Raleway";
  font-size: 17px;
  font-weight: 600;
}

.right-section .form-group input,
.right-section .form-group select,
.right-section .form-group textarea {
  width: 100%;
  padding: 8px;
  /* border: 0px solid #ccc; */
  border-radius: 5px;
  height: 40px;
  /* background-color: #ffffff; */
  border-radius: 15px;
  font-family: "Raleway";
  /* opacity: 0.6; */
  font-weight: 600;
}

textarea {
  height: 100px;
}

.label-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;
}

.label-icon {
  margin-right: 5px;
}

.edit-icon {
  cursor: pointer;
  color: #999;
  font-family: "Raleway";
  font-size: 14px;
}

button {
  /* color: #fff; */
  /* border: none; */
  border-radius: 5px;
  cursor: pointer;
  /* transition: background-color 0.3s; */
}

.profile-sidebar {
  display: flex;
  justify-content: center;
  align-items: center;
  /* margin-top: -80px; */
}

.divider-horizontal {
  width: 100%;
  height: 1px;
  background-color: #ccc;
  margin: -15px 0px 10px 0px;
}
.left-section h4 {
  font-family: Mulish;
  font-weight: bold;
}
.left-section p {
  font-family: raleway;
  font-weight: 400;
  font-size: 12px;
  color: white;
}

.contact-details {
  text-align: left;
  padding: 20px;
}
.contact-details h6 {
  margin: 0px;
  font-family: raleway;
  font-weight: bold;
}
.contact-item {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  margin-bottom: 10px;
  margin-top: 10px;
}
.contact-item a {
  text-decoration: none;
  /* color: white; */
  font-size: 14px;
  font-family: raleway;
}
.contact-details .label {
  font-family: raleway;
  font-size: 14px;
}
.contact-item span {
  font-family: raleway;
  font-size: 18px;
}
.image-text {
  display: flex;
  gap: 20px;
}
.image-text img {
  display: flex;
  justify-content: center;
  align-items: center;
}

.profile-container textarea {
  width: 100%;
  padding: 10px;
  /* border: 0px; */
  width: 100%;
  border-radius: 10px;
  font-family: raleway;
  /* background-color:#f5f5fe; */
}

.update-button {
  display: flex;
  justify-content: center;
  margin-top: 15px;
}
.update-button button {
  font-family: "Raleway";
  /* background-color: #5d54f1; */
  /* color: #fff; */
  /* padding: 10px 20px; */
  /* border: none; */
  border-radius: 5px;
  cursor: pointer;
  /* transition: background-color 0.3s; */
  width: 35%;
  font-weight: bold;
}
.box-parent {
  margin-top: 20vh;
}

.border-box {
  border: 2px solid black;
  padding: 10px;
  text-align: center;
  width: 230px;
  border-radius: 15px;
}
.input-container {
  position: relative;
  width: 200px;
}

.input-with-icon {
  width: 100%;
  padding: 10px;
  padding-right: 30px; /* Add some padding to the right to make space for the icon */
  box-sizing: border-box;
}

.input-icon {
  position: absolute;
  right: 10px;
  top: 50%;
  pointer-events: none; /* This ensures that the icon does not block clicks on the input */
  font-size: 16px;
  color: #999;
}
.border-box p {
  /* background-color: #cfcfcf; */
  margin-bottom: 0px;
  border-radius: 15px;
  padding: 20px;
  font-family: "Raleway";
  font-weight: 600;
  font-size: 18px;
  text-align: left;
}

.second-right-side {
  display: flex;
  flex-direction: column;
  gap: 30px;
}
.button-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.custom-button {
  display: flex;
  align-items: center;
  /* background-color: #5a4ff7; */
  border: none;
  border-radius: 5px;
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.2);
  color: white;
  cursor: pointer;
  padding: 0px 10px 0px 10px;
  font-size: 16px;
  text-decoration: none;
  transition: background-color 0.3s ease;
  box-shadow: 0 9px 14px rgba(0, 0, 0, 0.2), 0 13px 30px rgba(0, 0, 0, 0.2);
}

.custom-button .button-text {
  margin-right: 10px;
  font-size: 20px;
  font-weight: 600;
}

.custom-button .button-icon {
  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: 0 5px 5px 0;
  color: white;
  font-size: 16px;
}

.custom-button:hover {
  /* background-color: #4339c6; */
}

.user-profile-footer label {
  font-family: raleway;
  font-size: 17px;
  font-weight: 600;
}

.right-corner-image img {
  position: absolute;
  bottom: 0vh;
  right: 0;
  top: 75%;
  z-index: -1;
}
.right-edit {
  display: flex;
  gap: 10px;
}
.mobileIcon {
  display: none;
}
.laptopName {
  display: block;
}
mobileName {
  display: none;
}
.contactImages {
  width: 30px;
}
.mobile-second-right-section {
  display: none;
}
.right-corner-edit-icon {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  z-index: 10;
}

.right-corner-edit-icon img {
  width: 20px;
  height: 20px;
}
.user-profile-form-group {
  width: 100%;
}
.mobileName {
  display: none;
}
@media (max-width: 768px) {
  .profile-container {
    flex-direction: column;
  }
  .left-section {
    width: 100%;
    display: flex;
    padding: 15px 15px 15px 0px;
  }
  .mobileIcon {
    display: block;
    background-color: #202020;
  }
  .laptopImage {
    display: none;
  }
  .contact-item .label {
    display: none;
  }
  .laptopName {
    display: none;
  }
  .mobileName {
    display: block;
  }
  .contactName {
    display: none;
  }
  .right-section {
    width: 100%;
  }
  .right-section form {
    flex-direction: column;
  }
  .right-section .form-group {
    width: 100%;
  }
  .second-right-side {
    display: none;
  }
  .left-section h4 {
    margin-bottom: 0px;
  }
  .contactImages {
    width: 10px;
  }
  .image-text {
    gap: 10px;
    padding: 4px;
  }
  .contact-item {
    margin-bottom: 0px;
    margin-top: 0px;
  }
  .profile-sidebar {
    margin-left: 10px;
  }
  .contact-details {
    padding: 10px 0px 10px 10px;
  }
  .mobile-second-right-section {
    display: block;
    border: 2px solid black;
    padding: 10px;
    border-radius: 20px;
    cursor: pointer;
  }
  .middleContent {
    display: flex;
    padding: 10px;
    background-color: "#CFCFCF";
    border-radius: 20px;
    gap: 10px;
    align-items: center;
  }
  .firstText p {
    margin-bottom: 0px;
    font-size: 13px;
    font-weight: 600;
  }
  .contact-item span {
    font-family: raleway;
    font-size: 15px;
  }
}
