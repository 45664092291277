.faq-container {
  display: flex;
  flex-direction: row;
  gap: 50px;
  justify-content: space-between;
  margin: 10px auto;
  padding: 20px;
  /* background-color: #fff; */
  border-radius: 8px;
  margin-top: 80px;
}

.faq-container h1 {
  text-align: left;
  color: #000;
  font-weight: 800;
  font-size: 50px;
  font-family: Mulish;
}

.faq-left-part p {
  text-align: left;
  color: #0d0715;
  font-family: Raleway;
  font-weight: 500;
}
.contact-box p {
  text-align: center;
  /* color: #6b6a6a; */
  font-family: Raleway;
  /* font-size: 12px; */
}
.upper-part2 {
  text-align: center;
  /* color: black; */
  font-family: Raleway;
}
.faq-list {
  margin-top: 30px;
  /* height: 70vh; */
  overflow-y: scroll;
}

.faq-item {
  /* border-top: 1px solid #ecf0f1; */
  margin-bottom: 15px;
  border-radius: 10px;
}

.faq-question {
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  /* background-color: #000; */
  /* color: white; */
  padding: 10px;
  border-radius: 10px;
}

.faq-answer {
  padding: 10px;
  /* color: black; */
  /* background-color: rgba(93, 84, 241, 0.15); */
  /* border: 1px solid #f4f4f4; */
  border-radius: 10px;
}
.contact-box {
  text-align: center;
  margin-top: 50px;
  padding: 20px;
  /* background-color: #e7e5fd; */
  border-radius: 8px;
}

.contact-box button {
  /* margin-top: 10px; */
  /* padding: 6px 0px; */
  /* color: #fff; */
  /* background-color: #5d54f1; */
  /* border: none; */
  /* width: 50%; */
  /* border-radius: 10px; */
  /* cursor: pointer; */
  /* transition: background-color 0.3s; */
  /* font-family: Raleway; */
  /* font-weight: bold; */
}
.mobile-contact-box {
  text-align: center;
  margin-top: 50px;
  padding: 20px;
  background-color: #e7e5fd;
  border-radius: 8px;
}

.mobile-contact-box button {
  margin-top: 10px;
  padding: 6px 0px;
  color: #fff;
  background-color: #5d54f1;
  border: none;
  width: 50%;
  border-radius: 10px;
  cursor: pointer;
  transition: background-color 0.3s;
  font-family: Raleway;
  font-weight: bold;
}
.faq-left-part {
  position: relative; /* Ensure the container is positioned */
  padding: 20px;
  color: white;
}

.faq-left-part::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: url("../images/Chatbot/chat-back.svg");
  background-position: top left;
  background-repeat: no-repeat;
  background-size: contain;
  opacity: 0.6;
  z-index: 0;
}

.faq-left-part * {
  position: relative;
  z-index: 1;
}

.faq-left-part {
  width: 40%;
  padding: 70px 40px 80px 100px;
  width: 40%;
  padding: 70px 40px 80px 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.faq-right-part {
  width: 50%;
  width: 50%;
  align-items: center;
  display: flex;
  justify-content: center;
  /* height: 100vh; */
}
.faq-list::-webkit-scrollbar {
  display: none;
}
.faq-list {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.faq-bottom-image {
  position: absolute;
  bottom: 0%;
  left: 0%;
  z-index: 1;
  width: 13vw;
}
.mobile-contact-box {
  display: none;
}
.mobile-faq-bottom-image {
  display: none;
}

@media (max-width: 768px) {
  .faq-container {
    flex-direction: column;
  }
  .faq-left-part {
    padding: 0;
    width: 100%;
  }
  .faq-right-part {
    width: 100%;
  }
  .faq-container h1 {
    font-size: 40px;
  }
  .faq-list {
    height: none;
  }
  .faq-bottom-image {
    position: absolute;
    bottom: 30%;
    right: 0%;
    height: 90px;
    width: 125px;
  }
  .contact-box {
    display: none;
  }
  .mobile-contact-box {
    display: block;
    margin-top: 10px;
    border-radius: 15px;
  }
  .faq-container {
    gap: 0px;
    position: relative;
  }
  .faq-bottom-image {
    display: none;
  }
  .mobile-faq-bottom-image {
    display: block;
    position: absolute;
    z-index: 1;
    width: 30vw;
    bottom:0;
    right: 0;
  }
}
