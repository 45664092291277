.non-pri-centered-box {
  background-color: rgba(93, 84, 241, 0.15); /* 15% opacity */
  display: flex;
  box-sizing: border-box;
  border-radius: 10px;
  margin-top: 20px;
  margin-left: auto;
  margin-right: auto;
  width: 85%;
  flex-direction: row;
  overflow: hidden;
  margin-top: 110px;
}
.privileged-main-side {
  /* background-image: url("../images/selectcourt/main-background.svg"); */
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  flex: 1; /* Ensures the right-side takes up remaining space in the flex container */
  height: 100%;
  margin-top: 80px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
}

.right-side {
  /* background-image: url('../images/mycases/non-pri-vec.svg'); */
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  flex: 1; /* Ensures the right-side takes up remaining space in the flex container */
  height: 100%; /* Ensures the background image covers the entire height of the parent container */
}

.left-side {
  box-sizing: border-box;
  font-family: raleway;
  padding: 40px 70px;
  width: 50%;
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  justify-content: center;
}

.left-side h1 {
  color: #000;
  font-size: 30px;
  font-family: Mulish;
  font-weight: 900;
  width: 80%;
}

.left-side h2 {
  color: #000000;
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 5px;
}

.left-side p {
  color: #000000;
  width: 70%;
  font-size: 16px;
  font-weight: 500;
}

.left-side form {
  display: flex;
  flex-direction: column;
  width: 70%;
}

.left-side form input {
  padding: 10px;
  margin: 5px 0;
  border: 1px solid #ccc;
  border-radius: 5px;
  height: 39px;
  border-radius: 10px;
}

.left-side form button {
  padding: 5px;
  background-color: #5d54f1;
  color: white;
  border: none;
  width: 45%;
  border-radius: 10px;
  cursor: pointer;
  font-size: 1rem;
  font-family: raleway;
  font-weight: bold;
}

.left-side select{
  margin: 5px 0px;
}

.right-side {
  width: 50%;
  /* display: flex; */
  align-items: center;
  justify-content: center;
}

.right-side img {
  max-width: 100%;
  height: auto;
}
.left-side label {
  margin-bottom: 1px;
  color: #000;
  font-size: 14px;
  font-weight: 600;
}

.left-side input[type="text"]::placeholder {
  font-style: normal;
  font-size: 14px;
  padding: 10px 0px 0px 0px;
}
.mobile-right-side {
  display: none;
}
.headAndPara {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  text-align: left;
  border: none;
  border-radius: 15px;
}

@media (max-width: 768px) {
  .right-side {
    width: 100%;
  }
  .non-pri-centered-box {
    flex-direction: column;
    height: inherit;
    overflow: auto;
    width: 100%;
  }
  .left-side {
    width: 100%;
    padding: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 20px 0px;
  }
  .left-side h1 {
    width: 100%;
    display: contents;
  }
  .left-side h2 {
    text-align: center;
    margin-top: 10px;
    padding: 10px 15px 0px 20px;
    font-weight: 600;
    font-size: 15px;
  }

  .right-side {
    display: none;
  }
  .mobile-right-side {
    /* background-image: url('../images/mycases/non-pri-vec.svg'); */
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    flex: 1; /* Ensures the right-side takes up remaining space in the flex container */
    height: 100%; /* Ensures the background image covers the entire height of the parent container */
    display: block;
    margin-top: 20px;
    width: 100%;
  }
  .mobile-right-side img {
    width: 100%;
  }
  .headAndPara {
    margin-top: 10px;
    padding: 10px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 3px solid black;
    border-radius: 15px;
  }
  .left-side p {
    margin-top: 19px;
    width: 85%;
    text-align: center;
    font-weight: 600;
    font-size: 15px;
  }
  .left-side form {
    width: 85%;
  }
  .nonPriButton {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .privileged-main-side {
    background: none;
  }
}
