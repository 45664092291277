.high-court-container {
  text-align: center;
  padding: 20px;
  border-radius: 10px;
  background-image: url("../images/selectcourt/main-background.svg");
  background-repeat: no-repeat;
  background-position: center center;
  width: 100%;
  margin-top: 80px;
  background-size: contain;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 80px;
}

.high-court-container-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* margin-top: 80px; */
}

.high-court-container-content h1 {
  margin: 0px;
  text-align: center;
  font-weight: 800;
  font-size: 35px;
  color: black;
  font-family: Mulish;
}
.high-court-container-content p {
  color: #000;
  font-weight: 700;
  font-family: mulish;
  font-size: 20px;
  border: 3px solid black;
  padding: 10px;
  border-radius: 15px;
  width: 50%;
  margin-top: 12px;
  margin-bottom: 0px;
}
.img-text {
  display: flex;
  gap: 5rem;
  justify-content: space-between;
  align-items: center;
  justify-content: center;
}
.form-container {
  background-color: #ece8fc;
  border-radius: 25px;
  margin-top: 0px;
  padding: 30px 85px 30px 85px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: left;
  display: inline-block;
  width: 70%;
  margin-top: 25px;
}
.form-group {
  margin-bottom: 15px;
}
.form-group label {
  display: block;
  margin-bottom: 5px;
  /* color: #333; */
  font-family: raleway;
  /* font-weight: 500; */
  margin-right: 10px;
}
.form-group select,
.form-group input {
  width: 100%;
  padding: 10px;
  /* border: 1px solid #ccc; */
  border-radius: 15px;
  height: 50px;
  font-weight: 500;
  font-size: 15px;
  /* background-color: #f4f3f3; */
}
.form-group input[type="submit"] {
  background-color: #5d54f1;
  color: white;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s;
}
.form-group input[type="submit"]:hover {
  background-color: #5d54f1;
}
.back-arrow {
  display: inline-block;
  margin-bottom: 20px;
  color: #5d54f1;
  text-decoration: none;
  font-size: 24px;
  transition: color 0.3s;
}
.back-arrow:hover {
  color: #483c9f;
}
.icon {
  display: block;
  /* margin: 20px auto; */
}

.high-court-landing-top-image {
  width: 12vw;
  height: auto;
  position: absolute;
  bottom: 0%;
  left: 17%;
  z-index: 1;
  bottom: 0;
}
.mobile-submit {
  display: none;
}
.high-court-form-container {
  background-color: #ece8fc;
  border-radius: 25px;
  padding: 45px 30px 45px 30px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: left;
  display: inline-block;
  width: 100%;
  margin-top: 25px;
}
.laptop-submit {
  display: flex;
  align-items: center;
  justify-content: center;
  /* background: #5d54f1; */
  /* color: black; */
  /* color:white; */
  width: 50%;
  border-radius: 12px;
  height: 35px;
  box-shadow: 0 9px 14px rgba(0, 0, 0, 0.2), 0 13px 30px rgba(0, 0, 0, 0.2);
  cursor: pointer;
}

.laptop-submit button {
  background: none;
}
.submit-button {
  display: flex;
  align-items: center;
  justify-content: center;
}
.submit-laptop {
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (max-width: 768px) {
  .img-text {
    flex-direction: column;
    text-align: center;
  }
  .img-text img {
    display: none;
  }
  .high-court-container-content h1 {
    font-size: 25px;
  }
  .high-court-container-content p {
    width: 95%;
    padding: 10px;
  }
  .form-container {
    width: 100%;
    padding: 25px 20px;
  }
  .form-group label {
    font-weight: 700;
  }
  .laptop-submit {
    display: none;
  }
  .form-group {
    margin-bottom: 20px;
  }
  .mobile-submit {
    display: block;
    margin-top: 35px;
    width: 40%;
    height: 40px;
    /* background: #5d54f1; */
    box-shadow: 0 9px 14px rgba(0, 0, 0, 0.2), 0 13px 30px rgba(0, 0, 0, 0.2);
    font-family: "Raleway";
    font-weight: 600;
    font-size: 20px;
  }
  .mobile-submit input[type="submit"] {
    font-size: 26px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 65px;
  }
  

  .high-court-top-container {
    /* position: relative; */
    margin-top: 30px;
    display: none;
  }

  .high-court-landing-top-image {
    width: 30vw;
    height: auto;
    position: absolute;
    bottom: inherit;
    left: 50%;
    transform: translateX(-50%);
  }
  .close-button {
    position: absolute;
    top: 0;
    right: 2%;
    background: transparent;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
    font-size: 25px;
    font-weight: 700;
    color: black;
  }
  .mobile-privileged-main-side h1 {
    font-size: 35px;
    font-family: raleway;
    font-weight: 800;
  }
  .mobile-privileged-container .form-group select,
  .mobile-privileged-container .form-group input {
    height: 50px;
  }
  .high-court-mobile-privileged-centered-box {
    padding: 10px;
  }
  .high-court-container {
    margin-top: 80px;
    background: none;
  }
  .high-court-form-container {
    padding: 25px 30px 45px 30px;
  }
}
