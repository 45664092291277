.lawyer-filter-contain {
  background-color: #d9d9d9;
  margin-top: 1%;
}
.main-content {
  display: flex;
  padding: 20px;
  padding-top: 0px;
  /* height: 100vh; */
}
.divided-content {
  display: flex;
  width: 100%;
  border: 2px solid #000;
  border-radius: 10px;
}



.lawyer-filter-container-heading {
  display: flex;
  align-items: center;
  justify-content: center;
}
.lawyer-filter-container-heading h2 {
  font-family: mulish;
  font-size: 45px;
  font-weight: 800;
  margin-top: 100px;
  margin-bottom: 10px;
}
.filterlawyer-mobile-heading {
  display: none;
}
.lawyer-filter-contain {
  position: relative;
}



@media (max-width: 768px) {
  .lawyer-filter-container-heading h2 {
    display: none;
  }
  .filterlawyer-mobile-heading {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 80px;
  }
  .filterlawyer-mobile-heading h2 {
    font-family: mulish;
    font-size: 28px;
    font-weight: 800;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .main-content {
    padding: 0px;
    border: none;
  }
  .divided-content {
    border: none;
  }
}
