.waitLis-app-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  /* margin: 5em 0em 0em 0em; */
  padding: 50px;
  background: url("https://frontendcdn-fgabhsddewd9a2hw.z03.azurefd.net/frontend/waitListBack.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  gap: 100px;
  height: auto;
}

.waitList-container-contain {
  width: 100%;
  text-align: center;
  font-family: mulish;
  padding: 25px 0px 42px 87px;
}

.waitList-container-contain h1 {
  text-align: left;
  font-size: 50px;
  font-weight: 700;
  color: white;
}

.waitList-container-contain h5 {
  width: 30%;
  padding: 8px;
  border-radius: 18px;
  font-family: "Raleway";
  font-weight: 400;
  border: 6px solid #e7e5fd;
}

.waitList-container-contain p {
  color: white;
  font-size: 25px;
  text-align: left;
}

.WaitList-Right-Side img{
  width: 100%;
}

@media (max-width: 768px) {
  .waitLis-app-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: auto;
    padding: 30px;
    gap: 0px;
  }
  .waitList-container-contain{
    padding: 0px;
  }
}