.privileged-centered-box {
  background-color: #e7e5fd; /* 15% opacity */
  display: flex;
  flex-direction: row;
  align-items: center; /* Centers items vertically within the box */
  justify-content: center; /* Centers items horizontally within the box */
  box-sizing: border-box;
  border-radius: 25px;
  margin-top: 20px;
  margin-left: auto;
  margin-right: auto;
  width: 60vw;
  max-width: 800px; /* Ensures the box does not get too large on wider screens */
  padding: 10px; /* Adds padding inside the box for spacing */
}

.privileged-main-side h1 {
  font-size: 45px;
  margin-bottom: 20px;
  text-align: center;
  font-weight: bold;
  justify-content: center;
  align-items: center;
  margin: 2rem 2rem 2rem 2rem;
}

.form-group {
  margin-bottom: 1.5rem;
}

label {
  /* display: block; */
  margin-bottom: 0.5rem;
  font-weight: bold;
}

.privileged-container input[type="text"] {
  width: 100%;
  padding: 1rem;
  border-radius: 15px;
  border: 1px solid #666666;
  font-size: 1rem;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  height: 70px;
}

.info-text {
  font-size: 0.875rem;
  color: #555;
  font-family: raleway;
  text-align: left;
  display: flex;
  color: #000;
  font-style: normal;
}

.instruction-text {
  font-size: 15px;
  /* color: #333; */
  text-align: center;
  width: 70%;
}
.info-text p {
  color: #000;
}

.icon {
  font-size: 1.2rem;
  vertical-align: middle;
}

.btn {
  background-color: #000;
  color: white;
  border: none;
  border-radius: 10px;
  font-size: 18px;
  cursor: pointer;
  transition: background-color 0.3s;
  width: 40%;
  height: 48px;
  font-weight: bold;
  box-shadow: 0 9px 14px rgba(0, 0, 0, 0.2), 0 13px 30px rgba(0, 0, 0, 0.2);
}

.privileged-container {
  padding: 2rem 6rem 2rem 6rem;
}
.footer-content {
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
}

.bottom-left-image {
  position: absolute;
  bottom: 0%;
  left: 12%;
  width: 15%;
  height: auto;
  /* margin: 10px; */
}
.bottom-right-image {
  position: absolute;
  bottom: 0;
  right: 0%;
  height: 15rem;
  /* margin: 10px; */
}
.cases-privileged-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  background: url("../images/mycases/back-image.svg") no-repeat center center;
  background-size: cover;
  /* margin-top: 80px; */
  height: 100vh;
  margin: 0;
}

.add-case-section {
  /* border-radius: 10px; */
  width: 40%;
}
.my-cases-section {
  /* background-color: #f5f5f5; */
  padding: 40px 10px 0px 16px;
  margin-top: 60px;
  /* border-radius: 10px; */
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  width: 60%;
}

.add-case-section {
  position: relative;
  padding: 40px 150px 0px 50px;
}

.add-case-section h2 {
  color: #000;
  margin-bottom: 20px;
  font-family: "Mulish";
  font-weight: 900;
  font-size: 56px;
  margin-bottom: 20px;
}
.add-case-section img {
  position: absolute;
  width: 25%;
  /* left: 90%; */
  right: 0;
  bottom: 0;
}
.add-case-section .back-button {
  position: relative;
  left: 0;
  height: 50px;
  width: 50px;
  bottom: 20px;
  cursor: pointer;
}
.info-text img {
  position: static;
  width: 100%;
  right: 0px;
  left: 150px;
}

.form-group {
  margin-bottom: 20px;
}

/* input[type="text"] { */
  /* padding: 10px; */
  /* border-radius: 20px; */
  /* border: 2px solid #666666; */
  /* font-size: 16px; */
  /* height: 90px; */
  /* width: 100%; */
/* } */

.submit-btn {
  background-color: #000;
  color: white;
  padding: 5px 10px;
  width: 50%;
  border: none;
  border-radius: 10px;
  font-size: 16px;
  cursor: pointer;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s;
  box-shadow: 0 9px 14px rgba(0, 0, 0, 0.2), 0 13px 30px rgba(0, 0, 0, 0.2);
}

.info-text {
  font-size: 14px;
  color: #555;
  margin-top: 20px;
}

.icon {
  font-size: 20px;
  vertical-align: middle;
  margin-right: 5px;
}

.case-bottom-left-image {
  position: relative;
  bottom: 50px;
  /* left: 20px; */
}

.my-cases-section h2 {
  /* color: #000; */
  margin-bottom: 20px;
  font-family: "Mulish";
  font-weight: 900;
  font-size: 56px;
  margin-bottom: 20px;
}

.search-bar {
  width: 50%;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
  margin-bottom: 20px;
  font-size: 16px;
}

.case-list {
  display: flex;
  flex-direction: column;
  height: 70vh;
  overflow: auto;
}

.case-list::-webkit-scrollbar {
  display: none;
}

.case-list {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.case-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* background-color: #e7e5fd; */
  padding: 10px;
  border-radius: 5px;
  /* color: #000; */
  margin-bottom: 20px;
  border-radius: 10px;
  width: 100%;
}

.case-item:hover {
  background-color: #7b74f1;
  color: white;
  border-radius: 5px;
  width: 100%;
  cursor: pointer;
}

.case-items:hover .case-img {
  filter: invert(34%) sepia(100%) saturate(3253%) hue-rotate(226deg)
    brightness(70%);
  transition: filter 0.3s ease; /* Smooth transition for filter change */
}

.case-item span {
  flex: 1;
  font-family: Mulish;
  font-weight: bold;
  font-size: 15px;
}

.case-actions {
  display: flex;
  gap: 10px;
}

.add-case-para {
  font-family: raleway;
  font-weight: 600;
  font-size: 15px;
  width: 100%;
  /* padding: 40px 150px 0px 50px; */
}

.action-btn {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 18px;
}
.header-container h2 {
  width: 50%;
}
.action-btn {
  margin-top: -20px;
}
.btn:hover {
  background-color: #000;
  color: white;
}
.action-btn img {
  width: 25px;
  height: 25px;
}
.search-container {
  position: relative;
  display: flex;
  width: 100%;
  max-width: 400px;
}

.search-bar {
  width: 100%;
  height: 40px;
  padding-right: 10%;
  box-sizing: border-box;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding-left: 10px;
}

/* .search-icon {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 47px;
  width: 10%;
  background-color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
} */

.icon-img {
  width: 20px; /* Adjust as needed */
  height: 20px; /* Adjust as needed */
}
.header-container {
  display: flex;
  flex-direction: row;
}
.priviledge-footer {
  display: none;
}
.mobile-top-privilegde {
  display: none;
}
.mobilePrivildgeContainer {
  display: none;
}
/*Confirm Alert */
.react-confirm-alert-body > h1 {
  font-family: mulish !important;
  /* padding: 30px !important; */
  font-weight: 700;
}

.react-confirm-alert {
}

.react-confirm-alert-body {
  padding: 70px !important;
  width: 500px !important;
}
.react-confirm-alert-button-group > button {
  width: 25% !important;
  font-family: raleway !important;
  /* background: #5d54f1 !important; */
}

/* Ensure the button is styled appropriately */

@media (max-width: 768px) {
  .cases-privileged-container {
    flex-direction: column;
  }
  .add-case-section {
    display: none;
  }
  .my-cases-section {
    width: 100%;
    background-color: none;
    box-shadow: none;
    background-color: transparent;
  }
  .header-container {
    flex-direction: column;
    padding: 10px;
  }
  .search-icon {
    bottom: 20px;
  }
  .case-item {
    flex-direction: column;
    display: flex;
    align-items: baseline;
    padding: 15px;
    border-radius: 15px;
  }
  .case-item:hover {
    border-radius: 15px;
  }
  .mobile-top-privilegde {
    align-items: center;
    justify-content: center;
    display: flex;
  }
  .cases-privileged-container {
    background: none;
  }
  .header-container h2 {
    width: 100%;
    text-align: center;
    font-size: 30px;
  }
  .search-bar {
    background-color: #d8d8d8;
    border-radius: 12px;
    height: 45px;
  }
  .case-item span {
    font-weight: 400;
  }
  .back-image {
    width: 100%;
    height: auto;
    display: block;
    position: relative;
  }
  .case-list {
    height: 40vh;
    padding: 10px;
  }
  .addcaseBut {
    position: absolute;
    bottom: 5%;
    /* left:10%; */
  }

  .addcaseBut button {
    /* background-color: #5d54f1; */
    height: 42px;
    width: 100%;
    padding: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 23px;
    font-weight: 600;
  }
  .priviledge-footer {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .privileged-container {
    padding: 3rem 0rem 3rem 0rem;
  }
  .bottom-left-image {
    display: none;
  }
  .bottom-right-image {
    display: none;
  }
  .privileged-centered-box {
    width: 90%;
  }
  .privileged-centered-box {
    background-color: #d9d9d9;
  }
  .btn {
    margin-top: 20px;
  }
  .privileged-main-side h1 {
    font-size: 40px;
  }
  .mobile-privileged-main-side {
    background-color: #f3f3f3;
    height: 100%;
    border: 1px solid black;
    border-radius: 10px;
    padding: 10px;
    display: flex;
    flex-direction: column;
    /* position: absolute; */
    justify-content: center;
  }

  .mobile-privileged-container .form-group select,
  .mobile-privileged-container .form-group input {
    height: 90px;
  }
  .mobile-top-privilegde {
    position: fixed;
    bottom: -115%;
    left: 0;
    width: 100%;
    transition: bottom 0.3s ease-in-out;
    z-index: 10000;
    padding: 10px;
  }

  .mobile-top-privilegde {
    text-align: center;
  }

  .mobile-top-privilegde.show {
    bottom: 0;
    display: block;
    height: 100%;
    width: 100%;
    overflow: auto;
  }

  .addcaseBut {
    overflow: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    bottom: 1rem;
    left: 50%;
    transform: translateX(-50%);
    z-index: 11;
  }
  .mobilePrivildgeContainer {
    display: block;
  }
  .mobile-btn {
    /* background-color: #000; */
    width: 55%;
    height: 45px;
    font-size: 20px;
    box-shadow: 0 9px 14px rgba(0, 0, 0, 0.2), 0 13px 30px rgba(0, 0, 0, 0.2);
  }
  .info-text p {
    color: #000;
    align-items: center;
    /* display: flex; */
    justify-content: center;
    font-weight: 600;
  }
  .mobile-instruction-text {
    text-align: left;
    align-items: center;
    display: flex;
    justify-content: space-evenly;
    gap: 10px;
    font-weight: 500;
    align-items: flex-start;
  }
  .mobile-privileged-container .form-group input {
    height: 70px;
    font-weight: 700;
    /* color: black; */
    /* background: white; */
  }
  .mobile-footer-image {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .mobile-footer-image img {
    position: absolute;
    bottom: 0%;
    display: none;
    /* height: 29%; */
    width: 30%;
  }
  .search-container {
    max-width: none;
  }
  .instruction-text {
    width: 90%;
  }
  .privileged-main-side {
    margin-top: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: auto;
  }
  .react-confirm-alert-body {
    width: 400px !important;
  }
}
