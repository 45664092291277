:root {
  --card-margin: 4vw;
  --card-top-offset: 1em;
  --numcards: 5;
  --outline-width: 0px;
}

html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
  /* overflow: hidden;  */
}

html,
body {
  min-height: 100vh;
  height: auto;
  overflow-x: hidden; /* Prevent horizontal scrolling */
}

/* ✅ Ensure a scrollable wrapper */
.scroll-container {
  height: 100vh;
  overflow-y: auto; /* Allows scrolling */
}

/* body::-webkit-scrollbar {
  width: 0; 
  Hide the vertical scrollbar
} */

.centered-text {
  font-weight: 900;
  font-family: "Mulish", sans-serif; /* Make sure the font is properly imported */
  font-size: 45px;
  margin-bottom: 0px;
  text-align: center;
  color: black;
}

header,
main {
  width: 80vw;
  margin: 0 auto;
}

header {
  height: 6rem;
  display: grid;
  place-items: center;
}

.feature-section {
  display: flex;
  flex-flow: column;
  align-items: center;
}

.stack-comp {
  display: flex;
  flex-flow: column;
}

#cards {
  list-style: none;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(var(--numcards), var(--card-height));
  gap: var(--card-margin);
  margin: 0 auto; /* Center the cards horizontally */
  padding-bottom: calc(var(--numcards) * var(--card-top-offset));
  margin-bottom: var(--card-margin);
  padding: 20px;
}

#card-1,
#card-2,
#card-3,
#card-4,
#card-5 {
  position: sticky;
  top: 5%;
}

#card-5 {
  margin-bottom: 20vh;
}

.stack-card {
  position: sticky;
  top: 0;
  padding-top: calc(var(--index) * var(--card-top-offset));
  /* background-color: none; */
  border: none;
  /* background: #e5e4fd; */
  border-radius: 12px;
  box-shadow: 0 0.2em 1em rgba(0, 0, 0, 0.1), 0 1em 2em rgba(0, 0, 0, 0.1);
}

.card-content {
  color: #131212;
  border-radius: 2rem;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  padding: 1.4em;
  gap: 50px;
}

.card-left-side {
  width: 50%;
}

.card-right-side {
  display: flex;
  align-items: baseline;
  justify-content: center;
  width: 50%;
  padding-left: 50px;
  flex-direction: column;
}

.card-video {
  /* background: black; */
  height: 100%;
  width: 100%;
  border-radius: 10px;
  padding: 20px;
  margin-bottom: -15px;
}

.card-content > figure > video {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 0.4em;
}

.card-right-side h1 {
  font-weight: 800;
  /* font-size: 45px; */
  margin-bottom: 1rem;
  font-family: mulish;
}

.card-content p {
  font-weight: 400;
  line-height: 1.42;
  /* font-size: 20px; */
  font-family: raleway;
}

@media (max-width: 768px) {
  .card-content {
    flex-direction: column;
    gap: 15px;
    border-radius: 15px;
  }
  .card-right-side {
    width: 100%;
    padding-left: 0px;
    display: block;
  }
  .card-left-side {
    width: 100%;
  }
  #cards {
    padding: 0px;
  }
  main {
    width: 90vw;
  }
  .card-right-side .heading-text {
    font-weight: 800;
    /* font-size: 28px; */
  }
  .centered-text {
    font-size: 35px;
  }
  #card-5 {
    margin-bottom: 10vh;
  }
}

