.ITN-container {
  padding: 20px 50px;
  margin: 30px 50px;
  /* background: #f3f3f3; */
  display: flex;
  flex-direction: column;
  gap: 1rem;
  border-radius: 7px;
  text-align: center;
}

.ITN-heading {
  font-weight: bold;
}

.ITN-news-container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  overflow-x: auto;
  padding: 10px 0;
}

.ITN-news-container::-webkit-scrollbar {
  display: none;
}

.ITN-news {
  width: 180px;
  height: auto;
  display: block;
  padding: 0 10px;
  transition: transform 0.5s;
}

.ITN-news:hover {
  transform: scale(1.1);
}

@media (max-width: 768px) {
  .ITN-news-container {
    gap: 0px;
    justify-content: flex-start;
  }
}

@media (max-width: 500px) {
  .ITN-container {
    margin: 50px 0;
    padding: 20px;
  }
}
