/* BlogComponent.css */

/* Add this to your blog.css file */
/* body {
  background: url('../images/dipak\ our\ screens.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed; 
} */

.blog-container {
  padding: 60p;
}
.blog-container h2 {
  text-align: center;
  font-weight: 900;
  font-size: 45px;
  color: black;
  font-family: Mulish;
  margin: 6rem;
}
.blog-section {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  padding: 20px;
}

.blog-title {
  text-align: center;
  color: #fff;
}
.blog-item {
  margin-right: 10px; /* Adjust as needed */
}

.blog-item p {
  max-width: 200px; /* Adjust the maximum width as needed */
  white-space: pre-wrap;
  display: block;
  font-family: raleway;
  font-size: 12px;
  font-weight: 400; /* or any other value like '400' for normal */
  color: #000;
}
.slick-next:before {
  content: "→";
  color: black !important;
}
.slick-prev:before {
  content: "→";
  color: black !important;
}
.blog-item {
  margin-bottom: 20px;
  padding: 10px;
  border-radius: 12px;
  flex: 0 0 auto;
  width: calc(100% / 3 - 20px); /* Default width for larger screens */
  scroll-snap-align: start;
  margin-right: 10px;
}
.blog-item.behind {
  transform: translateX(-100%);
}
.blog-item h3 {
  margin-top: 0;
  max-width: 300px;
  white-space: pre-wrap;
  display: block;
  font-family: raleway;
  font-size: 16px;
  font-weight: 600;
  color: #5d54f1;
}

.blog-item img {
  width: 100%;
  height: auto;
}

.author-info {
  display: flex;
  align-items: center;
}

.author-info img {
  border-radius: 50%;
  margin-right: 10px;
  width: 50px;
  height: 50px;
}

.blog-section {
  border: "1px solid #ccc";
  border-radius: 10px;
  margin-bottom: 0px;
  gap: 20px;
  padding: 0px 100px 0px 100px;
  box-sizing: border-box;
  scroll-behavior: smooth;
}
.blog-section > div {
  flex: 0 0 auto; /* Ensure each blog item doesn't grow */
}
.blog-section::-webkit-scrollbar {
  height: 10px;
}

.blog-section::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 5px;
}

.blog-section::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}
/* h3{ 
    color:white
} */

@media (max-width: 768px) {
  .blog-item {
    width: calc(100% / 2 - 20px); /* Adjust for two items on smaller screens */
  }
  .blog-section {
    margin-left: 0px;
    margin-right: 0px;
  }
  .blog-container h2 {
    margin: 2rem 0rem 3rem 0rem;
    font-size: 38px;
  }
}

@media (max-width: 479px) {
  .blog-item {
    width: 100%; /* Show one item on even smaller screens */
  }
  .blog-section {
    padding: 30px;
  }
}
