.sidebar1 {
  width: calc(100% - 17rem); /* 15rem sidebar + 1rem margin on both sides */
  height: calc(100vh - 4rem);
  position: fixed;
  top: 3rem;
  left: 17rem; /* 15rem sidebar + 1rem left margin */
  background-size: contain;
  /* border-radius: 10px; */
  transition: left 0.3s ease;
  box-shadow: 0 8px 3px rgba(0, 0, 0, 0.2);
  margin: 20px 0px 0px 0px;
  /* background-color: #e7e5fd; */
  background: white;
  display: flex;
  flex-direction: column;
}

.sidebar {
  border-radius: 0;
}

.sidebar1::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url("../images/Chatbot/chat-back.svg") no-repeat right center;
  background-size: contain; /* Adjust this if necessary */
  opacity: 0.2;
  z-index: 1; /* Ensure the overlay is above the background but below the content */
  pointer-events: none; /* Allow interactions to pass through */
}

/* Chat.css */

.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  max-width: 80%;
  max-height: 80%;
  overflow: auto;
}

.overlay {
  position: fixed;
  top: 30%;
  left: 30%;
  right: 30%;
  bottom: 30%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000; /* Ensure the overlay is above other content */
}
.modal-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  /* background-color: white; */
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 9px 14px rgba(0, 0, 0, 0.2), 0 13px 30px rgba(0, 0, 0, 0.2);
  max-width: 400px;
  width: 80%;
  border-radius: 20px;
}

.modal-upper-section {
  display: flex;
  justify-content: space-between;
}

.closeIcon {
  background: no-repeat;
  color: black;
  position: relative;
  top: -15px;
  font-size: 20px;
}
.modal-overlay {
  background-color: rgba(0, 0, 0, 0.5);
}

.file-upload-modal {
  text-align: center;
}

.modal-heading {
  text-align: left;
  margin: 0px 0px 20px 0px;
  font-family: sans-serif, "Raleway";
  font-weight: 600;
  font-size: 25px;
}

.dropzone {
  /* border: 2px dashed #5d54f1; */
  padding: 20px;
  margin-bottom: 20px;
  cursor: pointer;
  /* background: #ececfa; */
}

.dropzone.active {
  /* border-color: #007bff; */
}

.selected-file {
  margin-bottom: 20px;
}

.modal-buttons {
  display: flex;
  justify-content: center;
  gap: 20px;
}

.close-button,
.upload-button {
  padding: 5px 10px;
  cursor: pointer;
  border: none;
  border-radius: 4px;
  font-size: 16px;
}

.close-button {
  background-color: #f44336;
  color: white;
}

.upload-button {
  /* background-color: #5d54f1; */
  color: white;
  font-family: "Raleway";
  font-weight: 500;
  box-shadow: 0 9px 14px rgba(0, 0, 0, 0.2), 0 13px 30px rgba(0, 0, 0, 0.2);
}

.main {
  display: flex;
  flex-direction: column-reverse;
  flex: 1;
  overflow-y: auto;
  margin: 0;
  padding: 0;
}

.chats {
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow-y: auto;
  scroll-behavior: smooth;
  gap: 0.5rem;
  margin-top: 1rem;
  /* padding: 1rem 8rem; */
  max-width: 100%;
}

.chats::-webkit-scrollbar {
  display: none;
}

.chats {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.chat {
  display: flex;
  align-items: flex-start;
  /* text-align: justify; */
  margin-top: 10px;
}

.bot {
  background: rgba(28, 30, 58, 1);
  width: fit-content;
  border-radius: 0.5rem;
  padding: 0.5rem;
}

.chatimg {
  object-fit: cover;
  width: 3rem;
  /* margin-right: 2rem; */
  border-radius: 1.5rem;
}
.useravatar {
  object-fit: cover;
  width: 3rem;
  margin-right: 2rem;
  border-radius: 1.5rem;
  /* width: 40px; */
  height: 40px;
  background-color: rgb(217 217 217);
  color: #5d54f1;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-weight: bold;
}

.inp {
  padding: 0;
  display: flex;
  align-items: center;
  width: 90%;
}

.inp > input {
  width: calc(100% - 3rem) !important;
  outline: none;
  padding: 0.5rem;
  /* color: white; */
  background: transparent;
  border: none !important;
}

.chatFooter {
  width: calc(100% - 0rem);
  padding: 7px 7px 7px;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: baseline;
  /* border-top: 1.5px solid #0d0d0d; */
  /* background-color: #e7e5fd; Ensure the footer has a background color */
  /* position: fixed; Fix the footer at the bottom */
  bottom: 50px;
  z-index: 10;
}

.chatFooter .inp {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 40px 0 0;
}

.chatFooter .inp input[type="text"] {
  flex: 1;
  margin: 0 10px;
  /* padding: 8px; */
  border: none;
  border-radius: 0px;
  /* background-color: white; */
  font-size: 20px;
  /* font-family: raleway; */
  font-family: sans-serif;
  /* color: black; */
  /* height: 53px; */
}

.chatFooter .inp button {
  /* background: transparent; */
  /* border: none; */
  /* color: white; */
  cursor: pointer;
}

.chatFooter .file-upload {
  display: flex;
  align-items: center;
}

.chatFooter .file-upload button {
  background: transparent;
  border: none;
  color: white;
  cursor: pointer;
  /* padding: 5px 10px; */
}

.chatFooter .file-upload div {
  display: flex;
  align-items: center;
}

.chatFooter .file-upload input[type="file"] {
  display: none;
}

.text-below-button {
  font-size: 12px;
  color: #0d0d0d;
  /* padding: 0px 70px 0px; */
  /* display: flex; */
  /* flex-direction: column; */
}
.text-below-button p {
  font-size: 12px;
  margin-bottom: 0px;
  font-family: sans-serif, raleway;
}

.case-info {
  width: 50%;
}

.inp > input,
.send,
.stop {
  background: transparent;
  border: none;
  /* padding: 8px; */
  /* display: flex; */
  /* align-items: center; */
  /* justify-content: center; */
}

.prompts button {
  /* border: 1px solid #000; */
  /* height: 55px; */
  align-items: center;
  width: 45%;
  /* margin-bottom: 10px; */
  display: flex;
  border-radius: 7px;
  /* color: black; */
}

/* Changes made here */
.chat p {
  font-family: sans-serif;
  /* font-size: 16px; */
  /* color: #0d0d0d; */
  font-weight: 500;
  /* margin-bottom: 10px; */
  /* line-height: 1.6; */
}
/* styles.css */

/* General styles for the prompts container */
.prompts {
  display: flex;
  align-items: center;
  margin-bottom: 1rem; /* Add some spacing between rows if necessary */
}

/* Styles for hidden prompts when conversation has started */
.prompts.hidden {
  display: none;
}

/* Styles for buttons */
.prompts button {
  /* background-color: transparent; */
  margin-right: 3rem;
}

/* Additional styles for button2 */
.prompts .button2 {
  margin-right: 0;
}
.chatbot-mobile-content {
  display: none;
}

.all-chats {
  overflow: hidden;
  overflow-y: scroll;
  height: calc(100vh - 17rem);
  display: contents;
}
.all-chats::-webkit-scrollbar {
  display: none;
}

.all-chats {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

/* modal Style */
.modal-content img {
  margin-bottom: 30px;
}
.modal-content p {
  font-family: sans-serif;
  font-weight: 600;
  color: #5f5f5f;
}
.Browse_button .belowPara {
  margin-top: 10px;
  font-weight: 500;
}
.Browse_button button {
  /* background-color: #5d54f1; */
  box-shadow: 0 9px 14px rgba(0, 0, 0, 0.2), 0 13px 30px rgba(0, 0, 0, 0.2);
  padding: 6px 20px;
  font-family: "Raleway";
  font-size: 15px;
  font-weight: 500;
  margin: 20px;
}
.modal-top {
  /* display: flex; */
  gap: 50px;
}
.modal-left-section h2 {
  font-size: 20px;
  font-family: "Raleway";
  font-weight: 600;
}
.modal-left-section {
  display: flex;
  width: 40%;
  justify-content: space-between;
}

.progress-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.progress-bar {
  width: 100%;
  height: 20px;
  border-radius: 5px;
  overflow: hidden;
}

.progress {
  height: 100%;
  background-color: #76c7c0;
}

.chat-message {
  font-size: 16px;
  font-family: sans-serif;
}

/* Responsive adjustments */
@media (max-width: 1000px) {
  .chatFooter {
    flex-direction: column;
    /* position: fixed;  */
    bottom: 0;
    align-items: baseline;
    width: 100%;
  }
  .chatFooter .inp {
    align-items: center;
  }

  .chatFooter {
    padding: 5px 10px;
    margin-top: 0px;
  }

  .chatFooter .file-upload {
    justify-content: center;
  }

  .send,
  .stop {
    padding: 7px;
  }

  .text-below-button {
    color: black;
    margin-left: 0.5rem;
    margin-top: 0rem;
  }

  .case-info {
    width: 100%;
  }
  .sidebar1 {
    height: -webkit-fill-available;
    top: 0rem;
  }
  .chatFooter .inp {
    margin: 0px;
  }
  .text-below-button {
    padding: 0px 30px 0px;
  }

  .text-below-button p {
    font-size: 10px;
    margin-bottom: 0px;
  }

  .chatFooter .inp input[type="text"] {
    height: 40px;
    margin: 0px;
  }
  .sidebar1 {
    margin: 0px;
  }
  .prompts {
    flex-direction: column;
    align-items: center;
  }
  .prompts button {
    margin-right: 0rem;
  }
  .chats {
    margin-top: 5.5rem;
    padding: 1rem;
  }
  .toggle-btn {
    top: 1.7rem;
    left: 5%;
  }
  .toggle-btn1 {
    display: none;
  }
  .prompts button {
    display: flex;
    border: 2px solid #383737;
    height: 3rem;
    width: 100%;
    border-radius: 16px;
  }
  .mobile-Chatbot-horizontal-line {
    border: none;
    width: 108%;
    height: 3px;
    top: 9%;
    position: fixed;
    background-color: #ececec;
    /* margin: 20px 0; */
    left: 0% !important;
    opacity: 1;
  }
  .chatbot-mobile-content {
    display: flex;
    position: absolute;
    top: 10%;
    left: 0%;
    padding-left: 20px;
    gap: 20px;
    width: 100%;
    align-items: center;
  }

  .chatbot-mobile-content img {
    margin-top: 3%;
  }

  .chatbot-mobile-content p {
    font-weight: 700;
    margin-bottom: 0;
    font-size: 24px;
  }
  .modal-top {
    flex-direction: column;
  }
  .modal-left-section {
    flex-direction: column;
    text-align: left;
  }
  .modal-buttons {
    align-items: center;
    justify-content: center;
  }
}

@media (max-width: 1000px) {
  .sidebar1 {
    width: 100%;
    left: 0rem;
  }
  .chats {
    overflow: hidden;
    overflow-y: auto;
    scroll-behavior: smooth;
    width: 100%;
    /* height: calc(100vh - 16rem); */
    gap: 0rem;
    display: flex;
    /* margin-bottom: 100px; */
    /* align-items:center; */
    justify-content: flex-start;
  }
  .chatFooter {
    width: 100%;
  }
}

@media (max-width: 1000px) {
  .sidebar1 {
    width: 100%;
    left: 0;
  }
  .inp {
    width: -webkit-fill-available;
  }

  .main {
    margin: 1.2rem;
    flex: 1;
    overflow: auto;
    /* margin-bottom: 100px; */
  }

  .chatimg {
    width: 2rem;
    margin-right: 10px;
  }
  .useravatar {
    width: 2rem;
    margin-right: 10px;
    height: 30px;
  }
  .button2 {
    margin-top: 10px;
  }
}